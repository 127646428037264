import React from 'react'
import { Grid } from '@material-ui/core'
import { Button } from '@mui/material';
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box"
import GetStarted from '../../atoms/getStarted'
import Banner from '../../atoms/banner';
import Howitworks from '../../atoms/Howitworks'
import Ourcorevalues from '../../atoms/Ourcorevalues';
import Footer from '../../atoms/Footer';
import Getintouch from '../../atoms/Getintouch';
import Navbar from '../../atoms/navbar';
import Events from '../../atoms/events';
import Involved from '../../atoms/involved';
import Mission from '../../atoms/mission';
import Partners from '../../atoms/Partners';
import './home.scss';
import Popup from '../../atoms/Popup';
import Sidebar from '../../Sidebar';
import { GeneralContext } from "../../../context/GeneralContextProvider";

const HomePage = () => {
   
    //--------------------------------------------------------------------------------------------//
    const { showSideBar, setShowSideBar } = React.useContext(GeneralContext)
 let sidebarObj;
 function onCreate() {
     sidebarObj.element.style.visibility = '';
 }
 function open() {
     console.log("Sidebar is opened");
 }
 function close() {
     console.log("Sidebar is closed");
 }
 // Open the Sidebar
 function openClick() {
     sidebarObj.show();
 }
 // Toggle(Open/Close) the Sidebar
 function toggleClick() {
     sidebarObj.toggle();
 }
 // Close the Sidebar
 function closeClick() {
     sidebarObj.hide();
 }

  
    return (
        <>
        <Box component="div">
        {/* <Sidebar id="default-sidebar" ref={Sidebar => sidebarObj = Sidebar} style={{ visibility: "hidden" }} close={close} open={open} created={onCreate}> */}
            <Grid container className="new">
            
 {/* //-----------------------------------------------------------------navbar---------------------------------------------------------------// */}
 {/* <Box component="div" className="nav-width" >
       
        <nav>
         <Grid item md={4} lg={4} xs={4}>
                <Box className="BurgerMenu-container"  onClick={toggleClick} id="toggle">
                        <Box className="burger"></Box>
                        <Box className="burger"></Box>
                        <Box className="burger"></Box> 
                </Box>
          </Grid>
          <Grid item md={4} lg={4} xs={4}>
            <Box component="div" className="image-place">
              <img src="./assets/images/Eyecracy.png"/>
              </Box>
          </Grid>
          <Grid item md={4} lg={4} xs={4}>
              <Box component="div" className="donate-btn-place">
                  <Button className="button-base">Donate</Button>
              </Box>
                      
          </Grid>
        </nav>
        
      </Box>
  */}
 {/* //----------------------------------------------------------------endofnavbar-----------------------------------------------------------//                */}
                <Grid item xs={12} sm={12}><Navbar /></Grid>
                <Grid item xs={12} sm={12}><Banner/></Grid>
                <Grid item xs={12} sm={12}><GetStarted/></Grid>
                <Grid item xs={12} sm={12}><Events/></Grid>
                <Grid item xs={12} sm={12}><Howitworks/></Grid>
                <Grid item xs={12} sm={12}><Involved/></Grid>
                <Grid item xs={12} sm={12}><Mission /></Grid>
                <Grid item xs={12} sm={12}><Ourcorevalues/></Grid>
                <Grid item xs={12} sm={12}><Getintouch/></Grid>
                <Grid item xs={12} sm={12}><Partners/></Grid>
                <Grid item xs={12} sm={12}><Footer/></Grid>
                
            </Grid>        
            {/* </Sidebar > */}
        </Box>
        
      
        </>
    )
}

export default HomePage



