import './TestMap1.scss';
import { useState, useRef, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, Tooltip } from "react-leaflet";
import { Icon } from "leaflet";
import { LatLng, LatLngBounds } from "leaflet";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import MapMarker from '../../../images/MapMarker.png';
import { Grid } from '@material-ui/core';
import Navbar from "../../atoms/navbar";
import { Typography } from '@mui/material';
import CurrentLocationFilled from '@mui/icons-material/MyLocation';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import CurrentLocation from '@mui/icons-material/LocationSearching';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Slider from '@mui/material/Slider';
import MarkerBlue from '../../../images/marker1.png'
import { countyList } from './Data';
import Chip from '@material-ui/core/Chip';
import Accordions from '../../atoms/Accordion';
import { Skeleton } from '@mui/material'
import { Asia, Europe, SouthAfrica, SouthAmerica, NorthAmerica, Oceania } from './Data';




export default function MapPage() {

    return (<>
        <Grid item xs={12} sm={12}><Navbar /></Grid>

        <Box className="map-section" >
            <Grid item xs={12} sm={12}>
                <SearchBar />
            </Grid>
        </Box>

        <Box className="map-section">
            <Map />
        </Box>

        <Box className="map-section border">
            <Typography variant='h5' py={2}> Recent Updates : </Typography>
            <Accordions />
        </Box>

    </>);
}


export const Map = () => {
    const mapRef = useRef(null);
    const [currentLoc, setCurrentLoc] = useState(false)
    const [position] = useState([46.945575, 11.467291])


    const getCurrentZoom = () => {
        return mapRef.current?.getZoom();
    }


    const [zoomLevel, setZoomLevel] = useState()

    useEffect(() => {
        let timer = setTimeout(() => {
            setZoomLevel(getCurrentZoom())
        }, 100)
        return () => timer
    }, [])

    const customicon = new Icon({
        iconUrl: MarkerBlue,
        iconSize: [40, 40],
    });



    const locateOnMapFn = (event, value) => {
        if (value) {

            mapRef.current.flyTo(value, 12, { duration: 1 });

        } else {

            mapRef.current.flyTo(position, 3, { duration: 1 });

        }
        setTimeout(() => {
            setZoomLevel(getCurrentZoom())
        }, 1000)
    }

    const tile = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    const mapBoxAccessToken = "pk.eyJ1IjoiYXZkaGVzaHBhbCIsImEiOiJjbG5vYzB2aHgwZWg5MmttcDVpN2t1YmF3In0.GuH5Z-QuL9xnSER2x1AWOg"



    const handleCurrentLocBtn = () => {
        if (!currentLoc) {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        locateOnMapFn(null, [latitude, longitude])
                        setCurrentLoc(true)
                    },
                    (error) => {
                        console.error("Error getting location:", error.message);
                    }
                );
            } else {
                console.error("Geolocation is not supported by your browser");
            }
        } else {
            setCurrentLoc(false)
            locateOnMapFn();
        }
    }



    const panLeft = () => mapRef.current.panBy([-100, 0]);
    const panRight = () => mapRef.current.panBy([100, 0]);
    const panUp = () => mapRef.current.panBy([0, -100]);
    const panDown = () => mapRef.current.panBy([0, 100]);

    const selectedCountry = useRef(null);

    const handleZoomChange = (level) => {
        setZoomLevel(level)
        mapRef.current?.setZoom(level)
    }


    const AsiaStyle = {
        fillColor: 'gray',
        weight: 2,
        opacity: 1,
        color: 'white',
        fillOpacity: 0.7,
    };

    const OceaniaStyle = {
        fillColor: 'orange',
        weight: 2,
        opacity: 1,
        color: 'white',
        fillOpacity: 0.7,
    };

    const EuropeStyle = {
        fillColor: 'blue',
        weight: 2,
        opacity: 1,
        color: 'white',
        fillOpacity: 0.7,
    }


    const handleCountryClick = (e) => {
        selectedCountry.current?.setStyle({ fillColor: 'gray' });

        const clickedCountry = e.target;

        clickedCountry.setStyle({ fillColor: 'green' });
        selectedCountry.current = clickedCountry;

        const bounds = clickedCountry.getBounds();
        e.target._map.fitBounds(bounds);
    };



    return (
        <>
            <Box className="map-container" >
                <Box position={'relative'} sx={{ padding: '30px 0' }} >
                    <MapContainer ref={mapRef} center={position} zoom={3} scrollWheelZoom={false} zoomControl={false}>
                        <TileLayer url='https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png' />

                        <GeoJSON data={Asia} style={AsiaStyle} onEachFeature={(feature, layer) => {
                            layer.on({
                                click: handleCountryClick,
                            });
                        }} >
                            {/* <Tooltip sticky >Tooltip</Tooltip> */}
                        </GeoJSON>
                        <GeoJSON data={Oceania} style={OceaniaStyle} onEachFeature={(feature, layer) => {
                            layer.on({
                                click: handleCountryClick,
                            });
                        }} >
                            {/* <Tooltip sticky >Tooltip</Tooltip> */}
                        </GeoJSON>
                        <GeoJSON data={Europe} style={EuropeStyle} onEachFeature={(feature, layer) => {
                            layer.on({
                                click: handleCountryClick,
                            });
                        }} >
                            {/* <Tooltip sticky >Tooltip</Tooltip> */}
                        </GeoJSON>

                    </MapContainer>

                    <Box className="navigaion-button-container" >
                        <NavigationButtons panLeft={panLeft} panRight={panRight} panUp={panUp} panDown={panDown} />
                    </Box>

                    <Box>
                        <Stack className="zoom-slider" spacing={1} >
                            <ZoomSlider
                                zoomLevel={zoomLevel}
                                setZoomLevel={setZoomLevel}
                                getCurrentZoom={getCurrentZoom}
                                handleZoomChange={handleZoomChange}
                            />
                        </Stack>
                    </Box>

                    <Box>
                        <button className="currentLocBtn" onClick={handleCurrentLocBtn} >{currentLoc ? <CurrentLocationFilled /> : <CurrentLocation />}</button>
                    </Box>
                </Box>
            </Box>


        </>
    )

}

const ZoomSlider = ({ zoomLevel, setZoomLevel, getCurrentZoom, handleZoomChange }) => {
    const handleZoomIn = () => {
        const zoom = Math.min(getCurrentZoom() + 1, 18)
        handleZoomChange(zoom)
    };

    const handleZoomOut = () => {
        const zoom = Math.max(getCurrentZoom() - 1, 1)
        handleZoomChange(zoom)
    };

    const handleRangeChange = (e) => {
        const value = e.target.value;
        handleZoomChange(value)
    }

    return (
        <>
            <Box>
                <IconButton className='IconButton' onClick={handleZoomIn}>+</IconButton>
            </Box>

            <Box height={100}>
                <Slider
                    aria-label="Zoom Level"
                    min={1}
                    max={18}
                    step={1}
                    value={zoomLevel || 1}
                    orientation="vertical"
                    onChange={handleRangeChange}
                    sx={{
                        color: 'rgba(0,0,0,0.87)',
                        '& .MuiSlider-track': {
                            border: 'none',
                            backgroundColor: 'red'
                        },
                        '& .MuiSlider-thumb': {
                            width: 16,
                            height: 16,
                            backgroundColor: '#fff',
                            '&:before': {
                                boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                            },
                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                boxShadow: 'none',
                            },
                        },
                    }}
                />
            </Box>

            <Box>
                <IconButton className='IconButton' onClick={handleZoomOut}>-</IconButton>
            </Box>
        </>
    );
};

const NavigationButtons = ({ panLeft, panRight, panUp, panDown }) => {
    return (
        <>
            <Box className="up">
                <IconButton className='IconButton' onClick={panUp}>
                    <ArrowDropUpIcon />
                </IconButton>
            </Box>
            <Box className="left-right">

                <IconButton className='IconButton' onClick={panLeft}>
                    <ArrowLeftIcon />
                </IconButton>

                <IconButton className='IconButton' onClick={panRight}>
                    <ArrowRightIcon />
                </IconButton>

            </Box>
            <Box className="down">
                <IconButton className='IconButton' onClick={panDown} >
                    <ArrowDropDownIcon />
                </IconButton>
            </Box>
        </>
    );
};


export const SearchBar = () => {

    const data = [
        { id: 1, label: "Disaster Response", isSelected: false },
        { id: 2, label: "Human rights", isSelected: false },
        { id: 3, label: "Education", isSelected: false },
        { id: 4, label: "Mental health", isSelected: false },
        { id: 5, label: "Nutrition", isSelected: false },
        { id: 6, label: "Climate change", isSelected: false },
        { id: 7, label: "Gender equality", isSelected: false },
        { id: 8, label: "Health", isSelected: false },
        { id: 9, label: "Arts and culture", isSelected: false },
    ]
    const [text, setText] = useState('');
    const [sugList, setSugList] = useState(data);

    const handleTextChange = (e) => {
        let { value } = e.target;
        setText(value)
    }

    const handleClear = () => {
        setText('')
        const updatedSugList = sugList.map(item => ({ ...item, isSelected: false }));
        setSugList(updatedSugList);
    }

    const handleSugClick = (id) => {
        const updatedSugList = sugList.map(item => ({
            ...item,
            isSelected: item.id === id ? !item.isSelected : false
        }));

        const selectedLabel = updatedSugList.find(item => item.id === id && item.isSelected)?.label || '';
        setText(selectedLabel);

        setSugList(updatedSugList);
    };




    return (
        <>
            <Box className="search-container" width={'100%'} >
                <FormControl fullWidth >
                    <TextField
                        placeholder="Search..."
                        variant="outlined"
                        size="medium"
                        value={text}
                        onChange={handleTextChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {text.length > 0 && <IconButton edge="end" onClick={handleClear} >
                                        <CloseIcon />
                                    </IconButton>}
                                    <IconButton edge="end">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ borderRadius: '100px' }}
                    />
                </FormControl>

                <Stack flexWrap={'wrap'} flexDirection={'row'} gap={'10px'} p={2} style={{ justifyContent: "center" }}>
                    {sugList?.map((obj, k) => {
                        return <Chip
                            label={obj.label}
                            variant="outlined"
                            color={obj.isSelected ? 'primary' : ''}
                            onClick={() => handleSugClick(obj.id)}
                        />
                    })}
                </Stack>
                {/* <Box className="search-option-container">
              {SearchLoading && searchText?.trim().length > 0 && <Typography p={1} >Loading...</Typography>}
              {searchList?.map((loc, k) => {
                return <Stack onClick={() => (setSearchList(), locateOnMapFn(null, loc), setSearchText(loc.ngo_name))} flexDirection={'row'} p={1} borderBottom={1} borderColor={'#d9d0d0'} sx={{ '&:hover': { backgroundColor: '#f3f3f3' }, cursor: 'pointer' }} flexWrap={'wrap'} >
                <Typography fontSize={'0.7rem'} ><strong> Place : </strong> {loc.place} &nbsp; </Typography>
                <Typography fontSize={'0.7rem'} >Name : {loc.ngo_name}  &nbsp; </Typography>
                <Typography fontSize={'0.7rem'} >Project : {loc.project_title}  &nbsp; </Typography>
                </Stack>
              })}
              {searchList?.length == 0 && searchText?.trim().length > 0 && <Typography p={1} >No Result</Typography>}
            </Box> */}
            </Box>
        </>
    )
}