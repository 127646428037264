import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import './App.css';
import NewNav from './components/atoms/Nav/index';
import HomePage from './components/pages/home';
import NewHomePage from './components/pages/homeNew/index';
import DonatePy from './components/pages/donate-py/ach-pay';
import TeamPage from './components/pages/team/index';
import ProjectPage from './components/pages/project/index';
import AboutPage from './components/pages/about';
import Donate from './components/pages/donate/index';
import LoginPage from './components/pages/login';
import Donationdash from './components/pages/NGO_donation';
import Myproject from './components/pages/NGO_myproject';
// import Schedule from './components/pages/NGO_schedule';
import Sidebar from './components/Sidebar';
import { Calendar } from '@material-ui/pickers';
import Schedule from './components/pages/NGO_schedule';
import Login from './components/pages/login';
import NotAnNGO from './components/pages/notAnNGO';
import Donor from './components/pages/donor';
import EyecracyCenter from './components/pages/eyecracyCenter';
import { GeneralContext } from "./context/GeneralContextProvider";
import Checkout from "./components/pages/checkout";
import Discover from './components/pages/Discover';
import ShowInterest from "./components/pages/showInterest";
// import ThemeWrapper from './components/templates/themewrapper';
import { baseAddress } from './constants';
import TestMap from './components/pages/TestMap/index'
import TestProject from './components/pages/TestProject/index'
import NGOPage from './components/pages/NGO';
import TestMap1 from './components/pages/testMap1'
import EyeCracyCenter from './components/pages/eyecracyCenter';
import Discoverus from './components/pages/Discover-us';
import Welcome from './components/pages/Welcome'
import LearnMore from './components/pages/Learn_more'

const stripePromise = loadStripe("pk_live_51N7Ic3Ggg9gYWr4n6TL9CXi9wu7JNEBu23bn6D5VECgGMODVrfw2rrhCMSYdr3CaQRdlR15rkoSi61OCcLhqr9by00NpwsVBaO");

function App() {
  return (
    <>
      <Router>
        <NewNav />
        <Switch>
          <Route exact path="/" component={NewHomePage} />
          <Route path="/team" component={TeamPage} />
          <Route path="/aboutUs" component={AboutPage} />
          <Route path="/project" component={ProjectPage} />
          <Route path="/donate">
            <Elements stripe={stripePromise}>
              <Donate stripe={stripePromise} />
            </Elements>
          </Route>
          <Route path="/donate-payment">
            <Elements stripe={stripePromise}>
              <DonatePy stripe={stripePromise} />
            </Elements>
          </Route>
          {/* Add other routes as needed */}
        </Switch>
      </Router>
    </>
  );
}

export default App;

