import React, { useState } from 'react';
import styled from 'styled-components';
import "./Uploadbutton.scss";

const FileUploadButton = styled.label`
  outline: none;
  display: inline-block;
  padding: 8px;
  background-color: #4e78f4;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
  height: 34px;
  font-size: 12px;
  color: white;
`;

const FileInput = styled.input`
  display: none;
`;

const UploadButton = ({selectedFile, setSelectedFile }) => {
  

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Handle the file change event
    console.log('Selected file:', file);
    setSelectedFile(file);
    // setTimeout(() => {
    //   setSelectedFile(null);
    // }, 3000); // Hide after 5 seconds (5000 milliseconds)
  };

  return (
    <div className='mobile-btn-view'>
      <FileUploadButton htmlFor="fileInput">Upload Logo</FileUploadButton>
      <FileInput id="fileInput" type="file" onChange={handleFileChange} />
      {selectedFile && (
        <p style={{fontSize:'12px',color:'green'}}>Selected file: {selectedFile.name}</p>
      )}
      <p style={{fontSize:'12px', marginBottom: "15px"}}>Your logo will be displayed on our home page</p>
    </div>
  );
};

export default UploadButton;
