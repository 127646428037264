import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { Switch, Redirect, Route, Router } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import Donor from './components/pages/donor';
import reportWebVitals from "./reportWebVitals";
import DonorBrand from "./components/pages/donorBrand";
import GeneralContextProvider from "./context/GeneralContextProvider";
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

ReactDOM.render(
  <React.StrictMode>


    <BrowserRouter>
      <Switch>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <GeneralContextProvider>
            <Route path="/donorBrand" ><DonorBrand /></Route>
            <App />
          </GeneralContextProvider>
        </SnackbarProvider>
      </Switch>

    </BrowserRouter>


  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
