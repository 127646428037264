import React from 'react';
import { useEffect, useRef  } from 'react';
import { Grid } from '@material-ui/core'
import { NavLink } from 'react-router-dom';
import { Button } from '@mui/material';
import { useState } from 'react';
import Navbar from '../../atoms/navbar';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box"
import NewFooter from '../../atoms/newFooter';
import './team.scss';
import gsap from 'gsap';
import { GeneralContext } from "../../../context/GeneralContextProvider";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Waypoint } from 'react-waypoint';
import Splitting from 'splitting'; 
import Slider from "react-slick";
import Tab from '@material-ui/core/Tab';
import { TabContext } from '@mui/lab';
import { TabList } from '@mui/lab';
import { TabPanel } from '@mui/lab';
import {
    CgInstagram
} from "react-icons/cg";
import {
ImLinkedin
} from "react-icons/im";
import { GetInTouchModal } from '../../atoms/Getintouch';

const TeamPage = () => {
    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = true; // Ensure the video is muted
            videoRef.current.play().catch(error => console.error("Video play failed:", error)); // Attempt to play the video
        }
    }, []);

    useEffect(() => {
        AOS.init({
          easing: 'ease-out-back',
          duration: 2000,
        });

        AOS.refresh();
      }, []);
    

    return (
        <>
        
        <Box component="div">
            <Box component="div" class="ourTeam-Banner">
                <Box component="div" className='ourTeam_title-Box'>
                    <Box component="div" className="ourTeam_title">Meet Our Team</Box>
                    <Box component="div" className="ourTeam_breadCrumb">
                        Home / <Box component="span" className='highlightedBreadCrumb'>Our Team</Box>
                    </Box>
                </Box>
            </Box>

            <Box component="div" className='nonGov_container'>
                <Grid container style={{alignItems: "center"}}>
                    <Grid item xs={12} sm={8} style={{paddingRight: "25px",}} data-aos="fade-up">
                        <Box component="div" className='v1-titleBox'>
                            <Box component="div" className='dashed'></Box>
                            <Box component="div" className='v1-sectionTitle'>
                                welcome to eyecracy
                            </Box>
                        </Box>
                        <Box component="div" className='v1-titleDesc'>
                            We are a non-governmental<br></br> organization
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} data-aos="zoom-out-left">
                        <Box component="div" className="v1-sectionContent">
                                At Eyecracy, our team embodies a values-driven approach, bringing together 
                                professionals with diverse backgrounds and expertise in global health, disaster 
                                preparedness, financial management, and program leadership.<br></br> From leaders in 
                                global health and disaster preparedness to experts in refugee health programs 
                                and seasoned program management professionals, our team's diverse blend of 
                                skills and experiences empowers us to address the multifaceted challenges 
                                facing communities worldwide.
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box component="div" className='team_box'>
                <Box component="div" className='teamMainTitle' data-aos="fade-up"> Meet Our Team </Box>
                <Box component="div" className='teamMainDesc' data-aos="fade-up">
                    With a shared commitment to innovation, collaboration, and impact-driven 
                    solutions,<br></br> we are dedicated to making a meaningful difference in the lives of 
                    those we serve. 
                </Box>
                <Grid container>
                    <Grid item xs={12} sm={3} style={{ padding: "10px"}} data-aos="zoom-in"> 
                        <Box component="div" className='teamCard'>
                            <Box component="div" className='team_img_box'>
                                <img src="./assets/images/georgina.jpg"></img>
                            </Box>
                            <Box component="div" className='team_role_box'>
                                <Box component="div" className='teamMem_name'>Georgina Nouaime</Box>
                                <Box component="div" className='teamMem_Role'>President</Box>
                                <Box component="div" className='socialIcons'>
                                    <Box component="span">
                                        {/*<Button className="facebook">
                                            <a href="https://www.instagram.com/eyecracy/" target="_blank"><CgInstagram  className="f-color"/></a>
                                        </Button>
                                        
                                        <Button className="facebook">
                                            <a href="https://www.instagram.com/eyecracy/" target="_blank"><CgInstagram  className="f-color"/></a>
                                        </Button>*/}

                                        <Button className="facebook">
                                            <a href="https://www.linkedin.com/in/georgina-aoun-nouaime-90504738" target="_blank">
                                                <ImLinkedin className="linkedin-icon f-color"/>
                                            </a>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ padding: "10px"}} data-aos="zoom-in"> 
                        <Box component="div" className='teamCard'>
                            <Box component="div" className='team_img_box'>
                                <img src="./assets/images/Claudio.jpg"></img>
                            </Box>
                            <Box component="div" className='team_role_box'>
                                <Box component="div" className='teamMem_name'>Claudio Pacillo</Box>
                                <Box component="div" className='teamMem_Role'>Vice President</Box>
                                <Box component="div" className='socialIcons'>
                                    <Box component="span">
                                        {/*<Button className="facebook">
                                            <a href="https://www.instagram.com/eyecracy/" target="_blank"><CgInstagram  className="f-color"/></a>
                                        </Button>
                                        
                                        <Button className="facebook">
                                            <a href="https://www.instagram.com/eyecracy/" target="_blank"><CgInstagram  className="f-color"/></a>
                                        </Button>*/}

                                        <Button className="facebook">
                                            <a href="https://www.linkedin.com/in/claudio-pacillo-93b172188" target="_blank">
                                                <ImLinkedin className="linkedin-icon f-color"/>
                                            </a>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} style={{ padding: "10px"}} data-aos="zoom-in"> 
                        <Box component="div" className='teamCard'>
                            <Box component="div" className='team_img_box'>
                                <img src="./assets/images/Njide.jpg"></img>
                            </Box>
                            <Box component="div" className='team_role_box'>
                                <Box component="div" className='teamMem_name'>Njideka Udochi</Box>
                                <Box component="div" className='teamMem_Role'>Senior Advisor</Box>
                                <Box component="div" className='socialIcons'>
                                    <Box component="span">
                                        {/*<Button className="facebook">
                                            <a href="https://www.instagram.com/eyecracy/" target="_blank"><CgInstagram  className="f-color"/></a>
                                        </Button>
                                        
                                        <Button className="facebook">
                                            <a href="https://www.instagram.com/eyecracy/" target="_blank"><CgInstagram  className="f-color"/></a>
                                        </Button>*/}

                                        <Button className="facebook">
                                            <a href="http://linkedin.com/in/njide-okonjo-udochi-md-mph-mba-ms-faafp-60ba2019" target="_blank">
                                                <ImLinkedin className="linkedin-icon f-color"/>
                                            </a>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} style={{ padding: "10px"}} data-aos="zoom-in"> 
                        <Box component="div" className='teamCard'>
                            <Box component="div" className='team_img_box'>
                                <img src="./assets/images/default.jpg"></img>
                            </Box>
                            <Box component="div" className='team_role_box'>
                                <Box component="div" className='teamMem_name'>Gladys Honein AbouHaidar</Box>
                                <Box component="div" className='teamMem_Role'>Senior Advisor</Box>
                                <Box component="div" className='socialIcons'>
                                    <Box component="span">
                                        {/*<Button className="facebook">
                                            <a href="https://www.instagram.com/eyecracy/" target="_blank"><CgInstagram  className="f-color"/></a>
                                        </Button>
                                        
                                        <Button className="facebook">
                                            <a href="https://www.instagram.com/eyecracy/" target="_blank"><CgInstagram  className="f-color"/></a>
                                        </Button>*/}

                                        <Button className="facebook">
                                            <a href="https://www.linkedin.com/in/gladys-honein-2b8a6325/" target="_blank">
                                                <ImLinkedin className="linkedin-icon f-color"/>
                                            </a>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} style={{ padding: "10px"}} data-aos="zoom-in"> 
                        <Box component="div" className='teamCard'>
                            <Box component="div" className='team_img_box'>
                                <img src="./assets/images/saraswath.jpg"></img>
                            </Box>
                            <Box component="div" className='team_role_box'>
                                <Box component="div" className='teamMem_name'>Babita Saaraswat</Box>
                                <Box component="div" className='teamMem_Role'>Senior Advisor</Box>
                                <Box component="div" className='socialIcons'>
                                    <Box component="span">
                                        {/*<Button className="facebook">
                                            <a href="https://www.instagram.com/eyecracy/" target="_blank"><CgInstagram  className="f-color"/></a>
                                        </Button>
                                        
                                        <Button className="facebook">
                                            <a href="https://www.instagram.com/eyecracy/" target="_blank"><CgInstagram  className="f-color"/></a>
                                        </Button>*/}

                                        <Button className="facebook">
                                            <a href="https://www.linkedin.com/in/babita-saaraswat-6b2018242/" target="_blank">
                                                <ImLinkedin className="linkedin-icon f-color"/>
                                            </a>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
             </Box>

             <NewFooter />
        </Box>
        </>
    )
}

export default TeamPage;