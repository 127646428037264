import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box'; 
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { InputLabel } from '@material-ui/core';
import { Label } from 'semantic-ui-react';
import './show_interest.scss';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    
    

  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    margin: theme.spacing(2),
    width: '400px',
    // background: 'lightgrey',
  },
  button: {
    margin: theme.spacing(2),
  },
}));

const ShowInterest = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [comments, setComments] = useState('');
  const [errors, setErrors] = useState({});
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [buffering, setBuffering] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    // Update the corresponding state and remove the error if applicable
    if (errors[name] !== undefined) {
      setErrors((prevState) => ({ ...prevState, [name]: undefined }));
    }
    if (name === 'name') {
      setName(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'phoneNumber') {
      setPhoneNumber(value);
    } else if (name === 'comments') {
      setComments(value);
    }
  };

  const handleSubmit = (event) => {
    const phoneNumberRegex = /^\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/;
    event.preventDefault();

    // Perform validation
    let validationErrors = {};
    if (!name) {
      validationErrors.name = 'Name is required';
    }
    if (!email) {
      validationErrors.email = 'Email is required';
    }
    if (!phoneNumber.match(phoneNumberRegex)) {
      validationErrors.phoneNumber = 'Please enter a valid phone number';
    }
    setErrors(validationErrors);

    // If no errors, submit the form and display a confirmation message
    if (Object.keys(validationErrors).length === 0) {
      setBuffering(true);
      setTimeout(() => {
        console.log(`Submitting form with name: ${name}, email: ${email}, phone Number: ${phoneNumber}, and comments: ${comments}`);
        setConfirmationOpen(true);
        setName('');
        setEmail('');
        setPhoneNumber('');
        setComments('');
        setBuffering(false); 
        setTimeout(() => {
          setConfirmationOpen(false);
        }, 3000);
      }, 2000); 
    



    }
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  return (
    <div className={`${classes.root} showinterest`}>
      <h1 style={{paddingBottom:'20px'}}>Show Interest</h1>
      <form className={classes.form} onSubmit={handleSubmit}>
        {/* <Label style={{textAlign:'start'}}>Username</Label> */}
        <TextField
          className={`${classes.textField} input-box`}
          placeholder="Name"
          // label="username"
          name="name"
          type="text"
          value={name}
          onChange={handleInputChange}
          error={errors.name !== undefined}
          helperText={errors.name}
        />
        <TextField
          className={`${classes.textField} input-box`}
          placeholder="Email"
          
          name="email"
          type="email"
          value={email}
          onChange={handleInputChange}
          error={errors.email !== undefined}
          helperText={errors.email}
        />
        <TextField
          className={`${classes.textField} input-box`}
          placeholder="Phone Number"
          
          name="phoneNumber"
          type="tel"
          value={phoneNumber}
          onChange={handleInputChange}
          error={errors.phoneNumber !== undefined}
          helperText={errors.phoneNumber}
        /> 
       
       <TextField
          className={classes.textField}
          placeholder="Message"
          multiline
          rows={4}
          value={comments}
          onChange={(event) => setComments(event.target.value)}
        />
         
        {/* <Snackbar open={confirmationOpen} autoHideDuration={6000} onClose={handleConfirmationClose}>
          <MuiAlert elevation={6} variant="filled" onClose={handleConfirmationClose} severity="success">
            Thank you for your interest!
          </MuiAlert>
        </Snackbar> */}
        {confirmationOpen && <p autoHideDuration={100}>Thanks you for showing your interest </p>}
        {buffering && <p>Loading...</p>}
        <Button className={classes.button} variant="contained" color="primary" type="submit">
          Submit
        </Button>
        
          
        
      </form>

      
    </div>
  );
};

export default ShowInterest;