import React from 'react';
import { Grid } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import "./getStarted.scss";
import { useEffect } from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { RouterFetchOptions } from "@remix-run/router";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import  UploadButton  from '../Uploadbutton';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:'700px',
  height: '520px',
  outline:'0',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:'20px',
  p: 4,
  '@media (max-width: 600px)': {
    top:'50%',
    width: '90%',
    maxWidth: '400px',
    height:'670px',
    padding:'10px',
  },
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    // [theme.breakpoints.down('sm')]: {
    //   // Media query for small screens
    //   flexDirection: 'column',
    // },
  },
  heading: {
    fontSize: '22px',
    paddingLeft: '10px',
    '@media (max-width: 600px)': {
      paddingLeft: '50px',
    },
  },
  
  

  root: {
    position: 'relative',
  },
  mobileview: {
    textAlign:'center',
    '@media (max-width: 600px)': {
      textAlign:'center',
    },
  },
  form: {
    //display: 'flex',
    //flexDirection: 'column',
    //alignItems: 'center',
  },
  textField: {
    margin: theme.spacing(1),
    width: '300px',
    '@media (max-width: 600px)': {
      width: '250px',
      margin:'5px',
    },
  },
  notchedOutline: {
    border: 'none',
    borderRadius:'5px',
  },
  button: {
    margin: theme.spacing(2),
    textAlign:'center',
    background:'#4e78f4',
  },
  errorHelperText: {
    fontSize: '11px',
    paddingLeft: '10px',
  },
}));
const handleFileInputChange = (event) => {
  const files = event.target.files; // Get the selected files
  // Process the files as needed (e.g., store them in state, validate, etc.)
};






const GetStarted = () => {
  const classes = useStyles();
  const [ngoName, setNGOName] = useState('');
  const [location, setLocation] = useState('');
  const [logo, setLogo] = useState('');
  const [ein, setEIN] = useState('');
  const [representativeName, setRepresentativeName] = useState('');
  const [representativeSurname, setRepresentativeSurname] = useState('');
  const [representativeEmail, setRepresentativeEmail] = useState('');
  const [representativeRole, setRepresentativeRole] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [buffering, setBuffering] = useState(false);
  const [open, setOpen] = React.useState(false);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (errors[name] !== undefined) {
      setErrors((prevState) => ({ ...prevState, [name]: undefined }));
    }
    
    if (name === 'ngoName') {
      setNGOName(value);
    } else if (name === 'location') {
      setLocation(value);
    } else if (name === 'logo') {
      setLogo(value);
    } else if (name === 'ein') {
      setEIN(value);
    } else if (name === 'representativeName') {
      setRepresentativeName(value);
    } else if (name === 'representativeSurname') {
      setRepresentativeSurname(value);
    } else if (name === 'representativeEmail') {
      setRepresentativeEmail(value);
    } else if (name === 'representativeRole') {
      setRepresentativeRole(value);
    } else if (name === 'email') {
      setEmail(value);
    }
  };
  const handleSubmit = (event) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    event.preventDefault();

    let validationErrors = {};
    if (!ngoName) {
      validationErrors.ngoName = 'NGO Name is required';
    }
    if (!location) {
      validationErrors.location = 'Location is required';
    }
    if (!ein) {
      validationErrors.ein = 'EIN is required';
    }
    if (!representativeName) {
      validationErrors.representativeName = 'Representative Name is required';
    }
    if (!representativeSurname) {
      validationErrors.representativeSurname = 'Representative Surname is required';
    }
    if (!representativeEmail.match(emailRegex)) {
      validationErrors.representativeEmail = 'Please enter a valid email address';
    }
    if (!representativeRole) {
      validationErrors.representativeRole = 'Representative Role is required';
    }
    if (!email.match(emailRegex)) {
      validationErrors.email = 'Please enter a valid email address';
    }
    setErrors(validationErrors);

    // If no errors, submit the form and display a confirmation message
    if (Object.keys(validationErrors).length === 0) {
       setBuffering(true);
      setTimeout(() => {
        console.log(`Submitting form with NGO Name: ${ngoName}, Location: ${location}, EIN: ${ein}, Representative Name: ${representativeName}, Representative Surname: ${representativeSurname}, Representative Email: ${representativeEmail}, Representative Role: ${representativeRole}, Email: ${email}`);
        setConfirmationOpen(true);
        setNGOName('');
        setLocation('');
        setLogo('');
        setEIN('');
        setRepresentativeName('');
        setRepresentativeSurname('');
        setRepresentativeEmail('');
        setRepresentativeRole('');
        setEmail('');
        setBuffering(false);
        setTimeout(() => {
          setConfirmationOpen(false);
        }, 3000);
      }, 2000); 
    



    }
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const discoverClick = () =>{
    if((window.location.host).indexOf("localhost")>-1) {
        window.location.href = "/map";
    } else {
        window.location.href = "/map";
    }
  }

  return (
    <>
      <Box component="div" className="getStarted-section">
        <Grid container>
          <Grid item md={12} lg={12} xs={12}>
            <Box className="section-title" component="div">Eyecracy makes your time profitable for charity<br />activities and NGO projects</Box>
          </Grid>
          <br />
          <Grid item md={12} lg={12} xs={12}><br />
            <Box className="section-desc" component="div">Time is the current equal currency to share.</Box>
          </Grid>
          <br />
          <Grid item md={12} lg={12} xs={12} className="btn">
            <Box component="div" className="btn-place">
              <Button className="button-base" onClick={discoverClick}>Go to Map</Button>
            </Box>
            <br />
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={`${classes.root} `}>
            <Box component={'div'} className={classes.headerContainer}>
              <Box component={'div'} >
                <h1 className={classes.heading}>Join Us</h1>
              </Box>
              <Box component={'div'} className="exit" style={{ height: '30px' }}>
                <Box className="ellipse-2" component="div">
                  <Box class="icon-2"></Box>
                </Box>
                <Box class="icon-exit" onClick={handleClose}>
                  &times;
                </Box>
              </Box>
            </Box>
            
            <form className={classes.form} onSubmit={handleSubmit}>
                <Box>
                  <Box component={'div'} className='ngo-details'>NGO Details:</Box>
                  <Grid container>
                    <Grid item md={6} className={classes.mobileview}>
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="NGO Name"
                      name="ngoName"
                      type="text"
                      value={ngoName}
                      onChange={handleInputChange}
                      error={errors.ngoName !== undefined}
                      helperText={errors.ngoName}
                      InputProps={{
                        style: { background: '#f7f7f8', height: '34px', fontSize: '12px',borderRadius:'10px'},
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />

                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Location"
                      name="location"
                      type="text"
                      value={location}
                      onChange={handleInputChange}
                      error={errors.location !== undefined}
                      helperText={errors.location}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    </Grid>
                    {/* <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Logo"
                      name="logo"
                      type="text"
                      value={logo}
                      onChange={handleInputChange}
                    /> */}
                    <Grid item md={6} className={classes.mobileview}>
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="EIN or tax federal identification"
                      name="ein"
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                      value={ein}
                      onChange={handleInputChange}
                      error={errors.ein !== undefined}
                      helperText={errors.ein}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={handleInputChange}
                      error={errors.email !== undefined}
                      helperText={errors.email}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    </Grid>
                  </Grid>
                    <Box className='representative-details'>Representative Details:</Box>
                  <Grid container>
                    <Grid item md={6} className={classes.mobileview}>
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Representative Name"
                      name="representativeName"
                      type="text"
                      value={representativeName}
                      onChange={handleInputChange}
                      error={errors.representativeName !== undefined}
                      helperText={errors.representativeName}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Representative Surname"
                      name="representativeSurname"
                      type="text"
                      value={representativeSurname}
                      onChange={handleInputChange}
                      error={errors.representativeSurname !== undefined}
                      helperText={errors.representativeSurname}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    </Grid>
                    <Grid item md={6} className={classes.mobileview}>
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Representative Email"
                      name="representativeEmail"
                      type="email"
                      value={representativeEmail}
                      onChange={handleInputChange}
                      error={errors.representativeEmail !== undefined}
                      helperText={errors.representativeEmail}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Representative Role"
                      name="representativeRole"
                      type="text"
                      value={representativeRole}
                      onChange={handleInputChange}
                      error={errors.representativeRole !== undefined}
                      helperText={errors.representativeRole}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    </Grid>
                  </Grid>
                </Box>
                <UploadButton />
                <Grid item md={12} sm={12}>
                  <Box>
                  <Box style={{textAlign:'center'}}>
                    <Box component={'div'}>
                  {confirmationOpen && <p autoHideDuration={100}>Thank you for showing your interest</p>}
                  </Box>
                  <Box component={'div'}>
                  {buffering && <img src="./assets/images/Spinner_small.gif" alt="Loading" />}
                  </Box>
                  </Box>
                  <Box style={{display:'flex',justifyContent:'center'}}>
                    <Button className={`${classes.button} center`} variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Box>
                  
                  </Box>
                  </Grid>
                   

            
            </form>
            
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default GetStarted;
