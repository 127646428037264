import React from 'react';
import { useEffect, useRef  } from 'react';
import { Grid } from '@material-ui/core'
import { NavLink } from 'react-router-dom';
import { Button } from '@mui/material';
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import './homeNew.scss';
import gsap from 'gsap';
import { GeneralContext } from "../../../context/GeneralContextProvider";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../../atoms/navbar';
import { Waypoint } from 'react-waypoint';
import Splitting from 'splitting'; 
import Slider from "react-slick";
import NewFooter from '../../atoms/newFooter';
import Tab from '@material-ui/core/Tab';
import { TabContext } from '@mui/lab';
import { TabList } from '@mui/lab';
import { TabPanel } from '@mui/lab';
import {
    CgInstagram
} from "react-icons/cg";
import {
ImLinkedin
} from "react-icons/im";
import { GetInTouchModal } from '../../atoms/Getintouch';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'700px',
    height: '350px',
    outline:'0',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:'20px',
    p: 4,
    '@media (max-width: 600px)': {
      top:'50%',
      width: '90%',
      maxWidth: '400px',
      height:'670px',
      padding:'10px',
    },
  };
  
  const useStyles = makeStyles((theme) => ({
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      // [theme.breakpoints.down('sm')]: {
      //   // Media query for small screens
      //   flexDirection: 'column',
      // },
    },
    heading: {
      fontSize: '22px',
      paddingLeft: '10px',
      '@media (max-width: 600px)': {
        paddingLeft: '50px',
      },
    },
    
    
  
    root: {
      position: 'relative',
    },
    mobileview: {
      textAlign:'center',
      '@media (max-width: 600px)': {
        textAlign:'center',
      },
    },
    form: {
      //display: 'flex',
      //flexDirection: 'column',
      //alignItems: 'center',
    },
    textField: {
      margin: theme.spacing(1),
      width: '300px',
      '@media (max-width: 600px)': {
        width: '250px',
        margin:'5px',
      },
    },
    notchedOutline: {
      border: 'none',
      borderRadius:'5px',
    },
    button: {
      margin: theme.spacing(2),
      textAlign:'center',
      background:'#4e78f4',
    },
    errorHelperText: {
      fontSize: '11px',
      paddingLeft: '10px',
    },
  }));

const NewHomePage = () => {
    const classes = useStyles();
  const [ngoName, setNGOName] = useState('');
  const [location, setLocation] = useState('');
  const [logo, setLogo] = useState('');
  const [ein, setEIN] = useState('');
  const [representativeName, setRepresentativeName] = useState('');
  const [representativeSurname, setRepresentativeSurname] = useState('');
  const [representativeEmail, setRepresentativeEmail] = useState('');
  const [representativeRole, setRepresentativeRole] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [buffering, setBuffering] = useState(false);
  const [open, setOpen] = React.useState(false);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (errors[name] !== undefined) {
      setErrors((prevState) => ({ ...prevState, [name]: undefined }));
    }
    
    if (name === 'ngoName') {
      setNGOName(value);
    } else if (name === 'location') {
      setLocation(value);
    } else if (name === 'representativeName') {
      setRepresentativeName(value);
    } else if (name === 'representativeRole') {
      setRepresentativeRole(value);
    }
  };
  const handleSubmit = (event) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    event.preventDefault();

    let validationErrors = {};
    if (!ngoName) {
      validationErrors.ngoName = 'NGO Name is required';
    }
    if (!location) {
      validationErrors.location = 'Location is required';
    }
   
    if (!representativeName) {
      validationErrors.representativeName = 'Representative Name is required';
    }
    if (!representativeRole) {
      validationErrors.representativeRole = 'Your message is required';
    }
    setErrors(validationErrors);

    // If no errors, submit the form and display a confirmation message
    if (Object.keys(validationErrors).length === 0) {
       setBuffering(true);
      setTimeout(() => {
        console.log(`Submitting form with NGO Name: ${ngoName}, Location: ${location}, EIN: ${ein}, Representative Name: ${representativeName}, Representative Surname: ${representativeSurname}, Representative Email: ${representativeEmail}, Representative Role: ${representativeRole}, Email: ${email}`);
        setConfirmationOpen(true);
        setNGOName('');
        setLocation('');
        setLogo('');
        setEIN('');
        setRepresentativeName('');
        setRepresentativeSurname('');
        setRepresentativeEmail('');
        setRepresentativeRole('');
        setEmail('');
        setBuffering(false);
        setTimeout(() => {
          setConfirmationOpen(false);
        }, 3000);
      }, 2000); 
    



    }
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };


        //--------------------------------------------------------------------------------------------//
{/*  const { showSideBar, setShowSideBar } = React.useContext(GeneralContext)
        let sidebarObj;
        function onCreate() {
            sidebarObj.element.style.visibility = '';
        }
        function open() {
            console.log("Sidebar is opened");
        }
        function close() {
            console.log("Sidebar is closed");
        }
        // Open the Sidebar
        function openClick() {
            sidebarObj.show();
        }
        // Toggle(Open/Close) the Sidebar
        function toggleClick() {
            sidebarObj.toggle();
        }
        // Close the Sidebar
        function closeClick() {
            sidebarObj.hide();
        } */}
       


    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = true; // Ensure the video is muted
            videoRef.current.play().catch(error => console.error("Video play failed:", error)); // Attempt to play the video
        }
    }, []);

    useEffect(() => {
        AOS.init({
          easing: 'ease-out-back',
          duration: 2000,
        });

        AOS.refresh();
      }, []);

    
      const projectsClick = () =>{
        if((window.location.host).indexOf("localhost")>-1) {
            window.location.href = "/project";
        } else {
            window.location.href = "/project";
        }
      }
    

    return (
        <>
        
        <Box component="div" sx={{overflowX:"hidden"}}>
            <Box component="div" className="bannerContainer" >
                <Box component="div" className='v1-banner' >
                    <video className='vedio-edit' ref={videoRef} loop muted style={{boxShadow: "none"}}>
                        <source src={'./assets/vedio/file_example_MP4_1280_10MG.mp4'} type = "video/mp4"/>
                            This browser doesn't support video tag.
                    </video>
                    <Box component="div" className='v1-bannerTextContainer'>'
                        <Box component="div" className='bannerPrimaryText'>
                            For Global Health and Disaster
                        </Box>
                        <Box component="div" className='bannerDescText'>
                            Eyecracy supports worlwide NGOs by providing consultation in global health care and
                                    <br></br>disaster preparedness
                        </Box>
                        <Box component="div" className='weDoBtn-container'>
                            <Button className="button-base"  onClick={projectsClick}>What We Do</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="div" className="v1-knowAbtContainer">
                <Grid container style={{alignItems: "center"}}>
                    <Grid item xs={12} sm={7} style={{paddingRight: "25px",}} className='aos-init aos-animate' data-aos="fade-up">
                        <Box component="div" className='v1-titleBox'>
                            <Box component="div" className='dashed'></Box>
                            <Box component="div" className='v1-sectionTitle'>
                                Who we are
                            </Box>
                        </Box>
                        <Box component="div" className='v1-titleDesc'>
                            We provide global health<br></br> and disaster <br></br> consultations
                        </Box>
                        <Box component="div" className="v1-sectionContent">
                            As an NGO, our main goal is to make the world better prepared for disasters. 
                            Through our specialized advice on global health and disaster management, we 
                            help communities and organizations get ready to handle emergencies 
                            effectively. <br></br>We work closely with partners to develop practical solutions that 
                            keep people safe and healthy, no matter what challenges they face. Our focus is 
                            on teamwork, fairness, and making sure everyone has the tools they need. 

                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Box component="div" className='v1-videoBox' data-aos="zoom-out-left">
                            <video className="image" controls style={{boxhadow: "none"}}>
                                <source src={'./assets/vedio/file_example_MP4_1280_10MG.mp4'} type = "video/mp4"/>
                                    This browser doesn't support video tag.
                            </video>
                        </Box>
                    </Grid>
                </Grid>

                {/*<Grid container style={{alignItems: "center", marginTop: "75px"}}>
                    <Grid item xs={12} sm={2}>
                        <Box component="div" className='v1-sectionTitle'>our partners</Box>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <Box component="div" className="borderDiv"></Box>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} sm={2}>
                        <Box component="div" className='v1-sectionLogo'>
                            <img src="/assets/images/compLogo3.jpg"></img>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Box component="div" className='v1-sectionLogo'>
                            <img src="/assets/images/compLogo3.jpg"></img>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Box component="div" className='v1-sectionLogo'>
                            <img src="/assets/images/compLogo3.jpg"></img>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Box component="div" className='v1-sectionLogo'>
                            <img src="/assets/images/compLogo3.jpg"></img>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Box component="div" className='v1-sectionLogo'>
                            <img src="/assets/images/compLogo3.jpg"></img>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Box component="div" className='v1-sectionLogo'>
                            <img src="/assets/images/compLogo3.jpg"></img>
                        </Box>
                    </Grid>
                </Grid>*/}
            </Box>

            <Box component="div" className='v1_discEyecracy'>
                <Box component="div" className='v1-titleBox'>
                    <Box component="div" className='v1-sectionTitle'>
                        Learn more
                    </Box>
                    <Box component="div" className='dashed'></Box>
                </Box>
                <Box component="div" className='v1_discEyecracy_title'>
                    Discover Eyecracy
                </Box>
                <Box component="div" className='v1_discEyecracy_desc' data-aos="zoom-out" data-aos-delay="200">
                    Our objectives encompass a holistic approach to building resilience 
                    and fostering positive change in global health and disaster 
                    preparedness. Eyecracy aims to strengthen the capacity of local NGOs 
                    by providing comprehensive training programs in primary health care, 
                    community health systems, and disaster protocols and we focus on 
                    developing standardized protocols and guidelines for disaster 
                    response and management to ensure consistency and effectiveness in 
                    resource allocation and service delivery. <br></br>Eyecracy is committed to 
                    promoting equity, inclusion, and social justice in all our initiatives, 
                    ensuring that resources are allocated fairly and equitably to address 
                    health disparities and humanitarian crises worldwide.
                </Box>
            </Box>

            <Box component="div" className='v1-thirdContainer'>
                <Grid container style={{alignItems: "center"}}>
                    <Grid item xs={12} sm={5}>
                        <Box component="div" className='v1-videoBox'  data-aos="zoom-out-right">
                            <video className='vedio-edit' controls style={{boxhadow: "none"}}>
                                <source src={'./assets/vedio/file_example_MP4_1280_10MG.mp4'} type = "video/mp4"/>
                                    This browser doesn't support video tag.
                            </video>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7} style={{paddingLeft: "10%"}} className='aos-init aos-animate' data-aos="fade-up">
                        <Box component="div" className='v1-titleBox'>
                            <Box component="div" className='dashed'></Box>
                            <Box component="div" className='v1-sectionTitle'>
                                eyecracy team
                            </Box>
                        </Box>
                        <Box component="div" className='v1-titleDesc'>
                            Welcome to Eyecracy
                        </Box>
                        <Box component="div" className="v1-sectionContent">
                            Eyecracy's team comprises professionals with diverse backgrounds 
                            and expertise spanning global health, disaster preparedness, financial 
                            management, and program leadership. <br></br> Among our members are 
                            leaders in global health and disaster preparedness and program 
                            management professionals. This diverse blend of skills and experiences 
                            equips Eyecracy to tackle the multifaceted challenges confronting 
                            communities worldwide. With a shared commitment to innovation, 
                            collaboration, and impact-driven solutions, our team is dedicated to 
                            making a meaningful difference in the lives of those we serve. 
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box component="div" className='currentProjects-container'>
                <Box component="div" className='v1-titleBox aos-init aos-animate' data-aos="fade-up">
                    <Box component="div" className='dashed'></Box>
                    <Box component="div" className='v1-sectionTitle'>
                        our project
                    </Box>
                </Box>
                <Box component="div" className='v1-titleDesc aos-init aos-animate' data-aos="fade-up">
                    Current projects where<br></br> Eyecracy is involved:
                </Box>
                <TabContext value={value}>
                    <Box  sx={{ borderBottom: 1,  borderColor: "divider" }} className="tabs"  >
                        <Box className="tablist" data-aos="fade-left" data-aos-delay="200">
                            <TabList  onChange={handleChange} aria-label="lab API tabs example"  >
                            
                                    <Tab  label="All" value="1" className="tab-styles"/>
                                    <Tab  label="Donate" value="2" className="tab-styles"/>
                                    <Tab  label="Fresh Food" value="3" className="tab-styles"/>
                                    <Tab  label="Hospital" value="4" className="tab-styles"/>
                                    <Tab  label="Medicine" value="5" className="tab-styles"/>
                                    <Tab  label="Water" value="6" className="tab-styles"/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Grid container>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_1.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_2.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                           
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_3.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                           
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_4.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                           
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_5.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_6.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                           
                                        </Box>
                                    </Box>
                                </Grid>
                                
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container>
                            <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_4.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_5.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_6.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                 Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_1.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Help us to Send
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_2.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_3.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="3">
                        <Grid container>
                            <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_1.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Help us to Send
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_2.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_3.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_4.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_5.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_6.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                 Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                
                            </Grid>
                        </TabPanel>
                        <TabPanel value="4">
                            <Grid container>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_1.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Help us to Send
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_2.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_3.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_4.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_5.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_6.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                
                            </Grid>
                        </TabPanel>
                        <TabPanel value="5">
                            <Grid container>
                            <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_4.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_5.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_6.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_1.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Help us to Send
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_2.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_3.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="6">
                        <Grid container>
                            <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_1.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Help us to Send
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_2.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_3.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_4.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_5.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{padding: "10px 10px 20px 10px"}} data-aos="flip-up">
                                    <Box component="div" className='tabGrid-Box'>
                                        <Box component="div" className='tabGridImg-Box'>
                                            <img src="./assets/images/image_6.jpg"></img>
                                        </Box>
                                        <Box component="div" className='gridBottom_section'>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label'>
                                                        Coming soon...
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box component="div" className='grid_label1'>
                                                        New York, US
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box component="div" className='gridTitle'>
                                                Coming soon...
                                            </Box>
                                            <Box component="div" className='gridDesc'>
                                                We give a lifetime of support to soldiers and
                                                veterans from the British Army, and their
                                                immediate families, when they
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                
                            </Grid>
                        </TabPanel>
                    </Box>
                </TabContext>
            </Box>


            <Box component="div" className="joinUS-container" >
                <img src="./assets/images/image_9.jpg"></img>
                <Box component="div" className='joinUs-textBox' data-aos="zoom-in">
                    <Box component="div" className='joinUsBannerTitle'>
                        Join Us
                    </Box>
                    <Box component="div" className='joinUsBannerDesc'>
                        Embark on a journey towards a healthier tomorrow by becoming a part 
                        of the Eyecracy community. Whether you seek expert consultations, 
                        wish to contribute to transformative projects, or aspire to amplify your 
                        NGO's impact.
                    </Box>
                    <Box component="div" className='joinUsBannerBtn'>
                        <Button className="button-base" onClick={handleOpen}>Connect</Button>
                    </Box>
                </Box>
            </Box>

            <Box component="div" className='latestNew-container'>
                <Box component="div" className='v1-titleBox' data-aos="fade-up">
                    <Box component="div" className='dashed'></Box>
                    <Box component="div" className='v1-sectionTitle'>
                        Latest News
                    </Box>
                </Box>
                <Box component="div" className='v1-titleDesc' data-aos="fade-up">
                    We are creating a<br></br> responsive place
                </Box>
                
            </Box>

            <Box component="div" className='news-grid'>
                <Grid container>
                    <Grid item xs={12} sm={4} style={{ padding: "0 10px" }}  data-aos="flip-left" data-aos-delay="200">
                        <Box component="div" className='newsGrid'>
                            <Box component="div" className="internal_container">
                                <Box component="div" className='newsTitle'>
                                    Coming Soon...<br></br>
                                </Box>
                                <Box component="div" className='newsDesc'>
                                    content will come soon....
                                </Box>
                                <Box component="div" className='joinUsBannerBtn'>
                                    <Button className="button-base">What We Do</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    {/*<Grid item xs={12} sm={4} style={{ padding: "0 10px" }} data-aos="flip-left" data-aos-delay="200">
                        <Box component="div" className='newsGrid'>
                            <Box component="div" className="internal_container">
                                <Box component="div" className='newsTitle'>
                                    Mission smile 1k:<br></br> Outdoor charity
                                </Box>
                                <Box component="div" className='newsDesc'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Suspendisse varius enim in eros.
                                </Box>
                                <Box component="div" className='joinUsBannerBtn'>
                                    <Button className="button-base">What We Do</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ padding: "0 10px"}} data-aos="flip-left" data-aos-delay="200">
                        <Box component="div" className='newsGrid'>
                            <Box component="div" className="internal_container">
                                <Box component="div" className='newsTitle'>
                                    Mission smile 1k:<br></br> Outdoor charity
                                </Box>
                                <Box component="div" className='newsDesc'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    Suspendisse varius enim in eros.
                                </Box>
                                <Box component="div" className='joinUsBannerBtn'>
                                    <Button className="button-base">What We Do</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>   */} 
                </Grid>
            </Box>
            

            <Box component="div" className='currentProjects-container'>
                <Box component="div" className='v1-titleBox' data-aos="fade-up">
                    <Box component="div" className='dashed'></Box>
                    <Box component="div" className='v1-sectionTitle'>
                        Partners
                    </Box>
                </Box>
                <Box component="div" className='v1-titleDesc' data-aos="fade-up">
                    Our <br></br> Partners
                </Box>
                <Grid container style={{ marginTop: "50px", alignItems: "center" , }}>
                    <Grid item xs={12} sm={6} style={{ padding: "0 10px", textAlign: "center" , }}  data-aos="flip-left" data-aos-delay="200">
                        <Box component="div" className='PartnerLogo'>
                            <img src="./assets/images/GHLF_partner.jpg"></img>
                        </Box> 
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: "0 10px" }}  data-aos="flip-left" data-aos-delay="200">
                        <Box component="div" className='PartnerLogo'>
                            <img src="./assets/images/Echo.png"></img>
                        </Box>  
                    </Grid>
                </Grid>
            </Box>
            

            <NewFooter />
            
        </Box>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={`${classes.root} `}>
            <Box component={'div'} className={classes.headerContainer}>
              <Box component={'div'} >
                <h1 className={classes.heading}>Join Us</h1>
              </Box>
              <Box component={'div'} className="exit" style={{ height: '30px' }}>
                <Box className="ellipse-2" component="div">
                  <Box class="icon-2"></Box>
                </Box>
                <Box class="icon-exit" onClick={handleClose}>
                  &times;
                </Box>
              </Box>
            </Box>
            
            <form className={classes.form} onSubmit={handleSubmit}>
                <Box>
                  <Box component={'div'} className='ngo-details'>NGO Details:</Box>
                  <Grid container>
                    <Grid item md={6} className={classes.mobileview}>
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="NGO Name"
                      name="ngoName"
                      type="text"
                      value={ngoName}
                      onChange={handleInputChange}
                      error={errors.ngoName !== undefined}
                      helperText={errors.ngoName}
                      InputProps={{
                        style: { background: '#f7f7f8', height: '34px', fontSize: '12px',borderRadius:'10px'},
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />

                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Location"
                      name="location"
                      type="text"
                      value={location}
                      onChange={handleInputChange}
                      error={errors.location !== undefined}
                      helperText={errors.location}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    </Grid>
                    <Grid item md={6} className={classes.mobileview}>
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Representative Name"
                      name="representativeName"
                      type="text"
                      value={representativeName}
                      onChange={handleInputChange}
                      error={errors.representativeName !== undefined}
                      helperText={errors.representativeName}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Your Message"
                      name="representativeRole"
                      type="text"
                      value={representativeRole}
                      onChange={handleInputChange}
                      error={errors.representativeRole !== undefined}
                      helperText={errors.representativeRole}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    </Grid>
                  </Grid>
                    
                </Box>
                <Grid item md={12} sm={12}>
                  <Box>
                  <Box style={{textAlign:'center'}}>
                    <Box component={'div'}>
                  {confirmationOpen && <p autoHideDuration={100}>Thank you for showing your interest</p>}
                  </Box>
                  <Box component={'div'}>
                  {buffering && <img src="./assets/images/Spinner_small.gif" alt="Loading" />}
                  </Box>
                  </Box>
                  <Box style={{display:'flex',justifyContent:'center', marginTop: "40px"}}>
                    <Button className={`${classes.button} center`} variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Box>
                  
                  </Box>
                  </Grid>
                   

            
            </form>
            
          </div>
        </Box>
      </Modal>
        </>
    )
}

export default NewHomePage;