import React from "react";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Grid, TextField } from '@material-ui/core'
import { Switch } from "@mui/material";
import { Route } from "react-router";
import Donationdash from "../../pages/NGO_donation";
import Myproject from "../../pages/NGO_myproject";
import Schedule from "../../pages/NGO_schedule";
//import Campaign from "../../../pages/Campaign";
//import Settings from "../../../pages/Settings";
import Button from '@mui/material/Button';
import Box from "@material-ui/core/Box"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useState } from "react";
import App from "../../../App";
import './donorBrand.scss';
import Tab from '@material-ui/core/Tab';
import { TabContext } from '@mui/lab';
import { TabList } from '@mui/lab';
import { TabPanel } from '@mui/lab';
import Card from "@material-ui/core/Card";
import Cardcontent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Sidebar from "../../Sidebar";
//import { Map, GoogleApiWrapper } from 'google-maps-react';
import Divider from "@material-ui/core/Divider";
import {
    CiSearch
} from "react-icons/ci";
import {
    BsChevronDoubleLeft, BsChevronDoubleRight
} from "react-icons/bs";
import {
    AiFillApple
} from "react-icons/ai";
import {
    SiNike
} from "react-icons/si";

import { MdOutlineTransitEnterexit } from "react-icons/md";
import Navbar from "../../atoms/navbar";
import Footer from "../../atoms/Footer";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
     width: '1050px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
   
  };


const DonorBrand = () => {
    
    const [value, setValue] = React.useState("1");
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  

     
   
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
    },
  },
};


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const theme = useTheme();
const [personName, setPersonName] = React.useState([]);

const handleChange2 = (event) => {
  const {
    target: { value },
  } = event;
  setPersonName(
    // On autofill we get a stringified value.
    typeof value === 'string' ? value.split(',') : value,
  );
};

const [details, setDetails] = useState(false);
const [visible, setVisible] = useState(true);
const [brand, setBrand] = useState(false);
const [remove, setRemove] = useState(true);
const [nike, setNike] = useState(false);
const [modal, setModal] = useState(false);




if(details) {
  document.body.classList.add('active-details')
} else {
  document.body.classList.remove('active-details')
}



const toggleNike = () => {
  setNike(!nike);
};

if(nike) {
  document.body.classList.add('active-nike')
} else {
  document.body.classList.remove('active-nike')
}


const donorBrand = () => {
  setVisible((prev) => !prev);
 
  setBrand(true)
}


 
    return (
        <>
        <Navbar />
        <Box component="div" className="donor-explorer">
            <Box component="div" className="explore-without-brand">
                <Box component="div" className="explore-title">Explore Causes</Box>
                <Box component="div" className="explore-brand">
                    <Box component="span">
                        Explore /
                    </Box>
                    <Box component="span">
                        <strong>without brand</strong>
                    </Box>
                </Box>
            </Box>

            <Box component="div" className="donate-donor-explorer">
                <Grid container>
                    <Grid item md={8} sm={8}>
                        <Grid container>
                                <Box component="div" className="select-cause" >
                                  <Grid item md={6} sm={6}>  
                                    <Box component="div" className="cause-place">
                                        <Box component="div" className="cause-title">
                                            Cause
                                        </Box>
                                        <Box component="div"  className="brand-dropdown" >
                                        <FormControl style={{marginLeft:'-17px'}}className="dropdown-brand-second ">
                                            <Select 
                                                multiple
                                                displayEmpty
                                                value={personName}
                                                onChange={handleChange2}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                    return <em className="placeholdername">Select cause</em>;
                                                    }

                                                    return selected.join(', ');
                                                }}
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem disabled value="">
                                                    <Box className="placeholdername"><em>Individual</em></Box>
                                                </MenuItem>
                                                <MenuItem  value="option1"> Option1 </MenuItem>
                                                <MenuItem  value="option2"> Option2 </MenuItem>
                                                <MenuItem  value="option3"> Option3 </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    </Box>
                                </Grid>
                                    <Grid item md={6} sm={6} className="brand-place">  
                                       <Box component="div" className="cause-title">
                                            Brand
                                        </Box>
                                        <Box component="div" className="brand-dropdown">
                                        <FormControl style={{marginLeft:'-17px'}}className="dropdown-brand-second ">
                                            <Select 
                                                multiple
                                                displayEmpty
                                                value={personName}
                                                onChange={handleChange2}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                    return <em className="placeholdername">Select Brand</em>;
                                                    }

                                                    return selected.join(', ');
                                                }}
                                                MenuProps={MenuProps}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem disabled value="">
                                                    <Box className="placeholdername"><em>Individual</em></Box>
                                                </MenuItem>
                                                <MenuItem  value="option1"> Option1 </MenuItem>
                                                <MenuItem  value="option2"> Option2 </MenuItem>
                                                <MenuItem  value="option3"> Option3 </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                            
                                   
                                    </Grid>
                                </Box>
                            </Grid>
                    </Grid>
                    <Grid item md={4} sm={4}>
                           <Box component="div" className="search-place">
                           
                                {/* <textarea
                                className="text-area"
                                placeholder="search" 
                                /> */}
                               < form className="form">
                                        <textarea
                                       className="text-area"
                                        placeholder="Search cause" 
                                        />
                                        < CiSearch className="search-icon"/>
                               </form>
                           
                              
                                 
                                
                            </Box>
                    </Grid>
                </Grid>
                <Grid container>
                {visible && (    <Grid item md={8} sm={8}>
                        <Grid container className="donate-for-education">
                            <Grid item md={4} lg={4} xs={4} className="">
                                <Box component={"div"}>
                                    <img className="lady" src="./assets/images/lady-3.jpg"/>
                                </Box>
                            </Grid>
                            <Grid item md={8} lg={8} xs={8} className="donate-for-education-heading">
                                    <Box component="div" className="donate-education">DONATE FOR EDUCATION</Box>
                                    <Box component="div" className="donate-education-desc">Vestibulum quam nisi, pretium a nibh sit amet, consectetur hendrerit mi. Aenean imperdiet lacus sit amet elit porta, et malesuada erat bibendum. Cras sed nunc massa. Quisque tempor dolor sit amet tellus malesuada, malesuada iaculis eros dignissim.</Box>
                                    <Box component="div" className="views-goal">
                                        <Grid item md={6} lg={6} xs={6} >  
                                            <Box component="div" className="show-interest" onClick={(donorBrand)}>shown interest</Box>
                                            <Box component="div" className="views-color">10,205</Box>
                                        </Grid>
                                        <Grid item md={6} lg={6} xs={6} >  
                                            <Box component="div" className="show-interest">goal</Box>
                                            <Box component="div" className="goal-color">$16,581</Box>
                                        </Grid>
                                    </Box>
                                    <Divider className="divider" />
                            </Grid>           
                        </Grid>
                        <Divider className="divider-down" />
                        <Grid container className="donate-for-education">
                            <Grid item md={4} lg={4} xs={4} className="">
                                <Box component={"div"}>
                                    <img className="lady" src="./assets/images/home-2.jpg"/>
                                </Box>
                            </Grid>
                            <Grid item md={8} lg={8} xs={8} className="donate-for-education-heading" >
                                    <Box component="div" className="donate-education">Help rebuild Homes</Box>
                                    <Box component="div" className="donate-education-desc">Vestibulum quam nisi, pretium a nibh sit amet, consectetur hendrerit mi. Aenean imperdiet lacus sit amet elit porta, et malesuada erat bibendum. Cras sed nunc massa. Quisque tempor dolor sit amet tellus malesuada, malesuada iaculis eros dignissim.</Box>
                                    <Box component="div" className="views-goal">
                                        <Grid item md={6} lg={6} xs={6} >  
                                            <Box component="div" className="show-interest">shown interest</Box>
                                            <Box component="div" className="views-color">10,205</Box>
                                        </Grid>
                                        <Grid item md={6} lg={6} xs={6} >  
                                            <Box component="div" className="show-interest">goal</Box>
                                            <Box component="div" className="goal-color">$16,581</Box>
                                        </Grid>
                                    </Box>
                                    <Divider className="divider" />
                            </Grid>           
                        </Grid>
                        <Divider className="divider-down" />
                        <Grid container className="donate-for-education">
                            <Grid item md={4} lg={4} xs={4} className="">
                                <Box component={"div"}>
                                    <img className="lady" src="./assets/images/people.jpg"/>
                                </Box>
                            </Grid>
                            <Grid item md={8} lg={8} xs={8} className="donate-for-education-heading">
                                    <Box component="div" className="donate-education">Help relocate the refugees</Box>
                                    <Box component="div" className="donate-education-desc">Vestibulum quam nisi, pretium a nibh sit amet, consectetur hendrerit mi. Aenean imperdiet lacus sit amet elit porta, et malesuada erat bibendum. Cras sed nunc massa. Quisque tempor dolor sit amet tellus malesuada, malesuada iaculis eros dignissim.</Box>
                                    <Box component="div" className="views-goal">
                                        <Grid item md={6} lg={6} xs={6} >  
                                            <Box component="div" className="show-interest">shown interest</Box>
                                            <Box component="div" className="views-color">10,205</Box>
                                        </Grid>
                                        <Grid item md={6} lg={6} xs={6} >  
                                            <Box component="div" className="show-interest">goal</Box>
                                            <Box component="div" className="goal-color">$16,581</Box>
                                        </Grid>
                                    </Box>
                                    <Divider className="divider" />
                            </Grid>           
                        </Grid>
                        <Divider className="divider-down" />
                        <Grid container className="donate-for-education">
                            <Grid item md={4} lg={4} xs={4} className="">
                                <Box component={"div"}>
                                    <img className="lady" src="./assets/images/elephant.jpg"/>   
                                </Box>
                            </Grid>
                            <Grid item md={8} lg={8} xs={8} className="donate-for-education-heading" >
                                    <Box component="div" className="donate-education">Save Rhino from poachers</Box>
                                    <Box component="div" className="donate-education-desc">Vestibulum quam nisi, pretium a nibh sit amet, consectetur hendrerit mi. Aenean imperdiet lacus sit amet elit porta, et malesuada erat bibendum. Cras sed nunc massa. Quisque tempor dolor sit amet tellus malesuada, malesuada iaculis eros dignissim.</Box>
                                    <Box component="div" className="views-goal">
                                        <Grid item md={6} lg={6} xs={6} >  
                                            <Box component="div" className="show-interest">shown interest</Box>
                                            <Box component="div" className="views-color">10,205</Box>
                                        </Grid>
                                        <Grid item md={6} lg={6} xs={6} >  
                                            <Box component="div" className="show-interest">goal</Box>
                                            <Box component="div" className="goal-color">$16,581</Box>
                                        </Grid>
                                    </Box>
                                    <Divider className="divider" />
                            </Grid>           
                        </Grid> 
                        <Divider className="divider-down" />
                        
                        {/* <Box component="div" className="three-buttons">
                                <Button className="backword"><BsChevronDoubleLeft/></Button>
                                <Button className="one">1</Button>
                                <Button className="two">2</Button>
                                <Button className="three">3</Button>
                                <Button className="farword"><BsChevronDoubleRight/></Button>
                        </Box> */}
                </Grid> )}
{/* ------------------------------------------------Donor Brand---------------------------------------------------------- */}
  {brand &&(  <Grid item md={8} sm={8}>
                        <Grid container className="donate-for-education">
                          <img className="African-lady" src="./assets/images/African-lady.jpg"/>  
                          <Box component="div" className="african-lady-desc">Donate For Education</Box>  
                          <Box component="div" className="donate-education-cause-desc">Vestibulum quam nisi, pretium a nibh sit amet, consectetur hendrerit mi.
                                 Aenean imperdiet lacus sit amet elit porta, et malesuada erat bibendum. Cras sed nunc massa. Quisque tempor dolor 
                                 sit amet tellus malesuada, malesuada iaculis eros dignissim. <br/>
                                 Duis facilisis nibh quam, sit amet interdum tellus sollicitudin tempor. Curabitur aliquam erat in nisl lobortis, 
                                 ut pellentesque lectus viverra. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque massa ipsum, 
                                 efficitur a fermen tum sed, suscipit sit amet arcu. Ut ut finibus tortor, eu ultrices turpis. Mauris vitae elit nec 
                                 diam elementum elementum. Mauris ante quam, consequat ac nibh placerat, lacinia sollicitudin mi. <br/><br/>

                                 Duis facilisis nibh quam, sit amet interdum tellus sollicitudin tempor. Curabitur aliquam erat in nisl lobortis,
                                  ut pellentesque lectus viverra. Aenean sodales aliquet arcu at aliquam. Vestibulum quam nisi, pretium a nibh sit
                                   amet, consectetur hendrerit mi. Aenean imperdiet lacus sit amet elit porta, et malesuada erat bibendum. Cras sed
                                    nunc massa. Quisque tempor dolor sit amet tellus malesuada, malesuada iaculis eros dignissim. Aenean vitae diam 
                                    id lacus fringilla maximus. Mauris auctor efficitur nisl, non blandit urna fermentum nec.
                          </Box>        
                        
                            <Grid container className="functinality">
                                   
                            </Grid>
                                <Grid container className="tab-panel">
                                <Box  sx={{ width: "75%", typography: "body1" }} className="tabpanel-width">
                                        <TabContext value={value}>
                                            <Box  sx={{ borderBottom: 1,  borderColor: "divider" }}   >
                                                <Box >
                                                    <TabList  onChange={handleChange} aria-label="lab API tabs example"  >
                                                           
                                                            <Tab  label="Donate" value="1" className="tab-styles"/>
                                                            <Tab  label="Collaborate" value="2" className="tab-styles"/>
                                                            <Tab  label="Show Interest" value="3" className="tab-styles" />
                                                            <Tab  label="share" value="4" className="tab-styles"/>
                                                    </TabList>
                                                </Box>
                                                <TabPanel value="1">
                                                <Box component="div" className="similar-causes">Brands supporting the cause</Box> 
                                                <Box component="div" className="brands-causeone">
                                                    <Grid container className="brands-container">
                                                        <Box className="facebook">
                                                           <SiNike  className="nike" onClick={(toggleNike)}/>
                                                        </Box>
                                                        <Box className="facebook">
                                                           <AiFillApple className="nike"/>
                                                        </Box>
                                                        <Box className="facebook">
                                                            <SiNike className="nike" />
                                                        </Box>
                                                        <Box className="facebook">
                                                            <AiFillApple className="nike"/>
                                                        </Box>
                                                    </Grid>
                                                </Box>  
                                                <Box component="div" className="similar-causes">Similar Causes</Box>          
                                                <Box component="div" className="similar-causes-desc">Aenean accumsan dignissim tortor,
                                                 et accumsan erat tincidunt at.
                                                 Nulla luctus odio leo, quis vehicula dui blandit eu. Nunc tristique diam odio,</Box>   
                                        <Box component="div" className="tab-cards">
                                        <Grid item lg={4} md={4} xs={4}className="show-interest-card-one">
                                            <Card  className="card-height">   
                                                <Cardcontent>
                                                        <Grid container>
                                                        </Grid>   
                                                        
                                                </Cardcontent>
                                            </Card>
                                         </Grid>      
                                         {/* -----2nd */}

                                         <Grid item lg={4} md={4} xs={4}className="show-interest-card-one">
                                            <Card className="card-height">   
                                                <Cardcontent>
                                                        <Grid container>
                                                        </Grid>   
                                                        
                                                </Cardcontent>
                                            </Card>
                                         </Grid>        
                                         {/* ------3rd                                    */}
                                         <Grid item lg={4} md={4} xs={4}className="show-interest-card-one">
                                            <Card  className="card-height">   
                                                <Cardcontent>
                                                        <Grid container>
                                                        </Grid>   
                                                        
                                                </Cardcontent>
                                            </Card>
                                         </Grid>    </Box>  
                                        </TabPanel>
      
                                       <TabPanel value="2">
                                                 collaborate
                                        </TabPanel>
     
                                         <TabPanel value="3">
                                                Show interest  
                                        </TabPanel>
                                        <TabPanel value="4">
                                                Share  
                                        </TabPanel>
                                            </Box>
                                            
            
                                        </TabContext>
                                    
                                </Box>
                                    
                                </Grid>
                        </Grid>

                    </Grid> )}

{/* ---------------------------------------------------End of Donor brand---------------------------------------------------- */}
                    

            
                    <Grid item md={4} sm={4} >
                            <Grid container>
                                    <Grid item md={12} sm={12} className="project-section">
                                            <Box component="div" className="background-color">
                                                    <Box component="div" className="title">Projects</Box>
                                                    <Box component="div"></Box>
                                                    <Box component="div" className="image">
                                                        <img className="maps" src="./assets/images/maps.jpg"></img>
                                                    </Box> 
                                            </Box>
                                    </Grid>
                            </Grid>
                        <Grid item md={12} sm={12} className="project-section">
                            <Box component="div" className="background-color">
                                    <Box component="div" className="latest-updates" >
                                            <Box component="div" className="latest">latest</Box>
                                            <Box component="div" className="updates">Updates</Box>
                                    </Box>
                                    <Box component="div"></Box>
                                            <Grid container className="img-desc">
                                                    <Grid item md={4} sm={4}>
                                                            <Box component="div">
                                                                    <img className="heart-image" src="./assets/images/Hands-heart.png"/>                                        
                                                            </Box>
                                                    </Grid>
                                                    <Grid item md={8} sm={8} className="text-slide">
                                                                <Box component="div" className="gone-ghana" >GONE TO GHANA – VOLUN- <br />TEERING IN AFRICA</Box>
                                                                <Box component="div" className="date">
                                                                    28 july 2002
                                                                </Box>
                                                    </Grid>
                                                    <Grid item md={4} sm={4}>
                                                                <Box component="div">
                                                                <img className="heart-image" src="./assets/images/Hands-heart.png"/>                                        
                                                                </Box>
                                                    </Grid>
                                                    <Grid item md={8} sm={8} className="text-slide">
                                                                    <Box component="div" className="gone-ghana" >GONE TO GHANA – VOLUN- <br />TEERING IN AFRICA</Box>
                                                                    <Box component="div" className="date">
                                                                    28 july 2002
                                                                    </Box>
                                                    </Grid>
                                                    <Grid item md={4} sm={4}>
                                                                    <Box component="div">
                                                                    <img className="heart-image" src="./assets/images/Hands-heart.png"/>                                        
                                                                    </Box>
                                                    </Grid>
                                                    <Grid item md={8} sm={8} className="text-slide">
                                                                        <Box component="div" className="gone-ghana" >GONE TO GHANA – VOLUN- <br />TEERING IN AFRICA</Box>
                                                                        <Box component="div" className="date">
                                                                        28 july 2002
                                                                        </Box>
                                                    </Grid>
                                            </Grid>
                                    </Box>
                        </Grid>
                        <Grid item md={12} sm={12} className="project-section">
                        <Box component="div" className="background-color">
                                <Box component="div" className="events-place" >
                                    <Box component="div" className="events">Events</Box>
                                  
                                    
                                    <Box component="div"></Box>
                                    <Box component="div" className="buttons">
                                    <Button className="popular-btn">Popular Events</Button>
                                    <Button className="upcoming-btn">Upcoming Events</Button></Box></Box>
                                <Grid container className="img-desc">
                                    <Grid item md={4} sm={4}>
                                        <Box component="div">
                                        <img className="heart-image" src="./assets/images/Hands-heart.png"/>                                        
                                        </Box>
                                    </Grid>
                                    <Grid item md={8} sm={8} className="text-slide">
                                       <Box component="div" className="gone-ghana" >GONE TO GHANA – VOLUN- <br />TEERING IN AFRICA</Box>
                                        <Box component="div" className="date">
                                        28 july 2002
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} sm={4}>
                                        <Box component="div">
                                        <img className="heart-image" src="./assets/images/Hands-heart.png"/>                                        
                                        </Box>
                                    </Grid>
                                    <Grid item md={8} sm={8} className="text-slide">
                                       <Box component="div" className="gone-ghana" >GONE TO GHANA – VOLUN- <br />TEERING IN AFRICA</Box>
                                        <Box component="div" className="date">
                                        28 july 2002
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} sm={4}>
                                        <Box component="div">
                                        <img className="heart-image" src="./assets/images/Hands-heart.png"/>                                        
                                        </Box>
                                    </Grid>
                                    <Grid item md={8} sm={8} className="text-slide">
                                       <Box component="div" className="gone-ghana" >GONE TO GHANA – VOLUN- <br />TEERING IN AFRICA</Box>
                                        <Box component="div" className="date">
                                        28 july 2002
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                      </Grid>
                       </Grid>
                   </Grid>
                
{/* --------------------------------------------------------------------------------------close grid container----------------------------- */}

{/* ---------------------------------------------------------------------------------------------------------------------------- */}
            </Box>

        </Box>  
{/* ---------------------------------------------------brand-popup------------------------------------------------------ */}
      
{nike && ( 
         <Grid className="modal">
         <Grid onClick={toggleNike} className="overlay"></Grid>
         <Box component="div"  className="modal-content">
             <Box sx={style} style={{}} className="nike-popup">
    
                <Grid container className="popup-container">
                        <Grid item md={12} sm={12} className="nike-heading">
                                <Box component="div" >
                                            You will be redirected to the donation page once you finish watching the video
                                </Box>
                       </Grid>
                    <Grid item md={12} sm={12} className="image-desc">
                          <Box><img className="nike-image" src="./assets/images/nike-two.jpg"/> </Box>
                        <Box component="div" className="Nike-vision">
                                    Nike React Vision
                        </Box>
                        <Box component="div" className="nike-product-code">
                                    Product Code: 1653567890/5234
                        </Box>
                        <Box component="div" className="nike-desc">
                                        Suspendisse feugiat venenatis eleifend. Maecenas ac accumsan nibh. In sit amet accumsan ex, nec tristique augue. 
                                        Maecenas ut eros non ex vulputate pulvinar. Vivamus fermentum sollicitudin felis, vel pellentesque elit rhoncus eu. 
                                        Donec varius ex ut felis euismod consectetur. Curabitur tellus erat, varius ut ullamcorper bibendum, luctus eu arcu. Praesent 
                                        eu risus nibh. Aliquam et pellentesque nisi. Praesent eu dictum lorem, nec dapibus orci. Ut at metus vel enim cursus laoreet 
                                        eget vitae risus.
                        </Box>
                        <Box component="div" className="btn-padding">
                        <Button className="learn-more-btn">Learn more</Button></Box>


                            
                    </Grid>
               </Grid>
             </Box> 
         </Box> 
    </Grid>
             
    
    )}

{/* -------------------------------------end brand popup-------------------------------------------------------- */}
        <Footer />
        </>  
    ) 
} 

export default DonorBrand;