import React, { useState } from 'react'
export const GeneralContext = React.createContext()
var qs = require('qs');

function GeneralContextProvider({ children }) {
    const [showSideBar, setShowSideBar] = React.useState(false);
    const [isOpen, setIsOpen] = useState(true)
    const [projectUpdateList, setProjectUpdateList] = React.useState()
    const [projectList, setProjectList] = React.useState();
    const value = {
        showSideBar, setShowSideBar, isOpen, setIsOpen, projectUpdateList, setProjectUpdateList, projectList, setProjectList
    }
    return (
        <div>
            <GeneralContext.Provider value={value} >
                {children}
            </GeneralContext.Provider>
        </div>
    )
}

export default GeneralContextProvider