import React from 'react'
import { Grid } from '@material-ui/core'
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import './Getintouch.scss';
import Textarea from '@mui/joy/Textarea';
import { useEffect } from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { RouterFetchOptions } from "@remix-run/router";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import UploadButton from '../Uploadbutton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '450px',
  //height: '520px',
  outline: '0',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  backgroundImage: 'linear-gradient(315deg, rgb(255, 255, 181) 0%, rgb(140, 198, 223) 74%)',
  p: 4,
  '@media (max-width: 600px)': {
    top: '50%',
    width: '90%',
    maxWidth: '400px',
    height: '670px',
    padding: '10px',
  },
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    // [theme.breakpoints.down('sm')]: {
    //   // Media query for small screens
    //   flexDirection: 'column',
    // },
  },
  heading: {
    fontSize: '22px',
    paddingLeft: '10px',
    '@media (max-width: 600px)': {
      paddingLeft: '50px',
    },
  },



  root: {
    position: 'relative',
  },
  mobileview: {
    textAlign: 'center',
    '@media (max-width: 600px)': {
      textAlign: 'center',
    },
  },
  form: {
    //display: 'flex',
    //flexDirection: 'column',
    //alignItems: 'center',
  },
  textField: {
    margin: theme.spacing(1),
    width: '300px',
    '@media (max-width: 600px)': {
      width: '250px',
      margin: '5px',
    },
  },
  notchedOutline: {
    border: 'none',
    borderRadius: '5px',
  },
  button: {
    margin: theme.spacing(2),
    textAlign: 'center',
    background: '#4e78f4',
  },
  errorHelperText: {
    fontSize: '11px',
    paddingLeft: '10px',
  },
}));

const eyecracyCenterClick = () =>{
  if((window.location.host).indexOf("localhost")>-1) {
      window.location.href = "/eyecracyCenter";
  } else {
      window.location.href = "/eyecracyCenter";
  }
}

const Getintouch = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box component="div" className="get-started-with-us-bg">
        <Grid container>
          <Grid item md={12} lg={12} xs={12}>
            <Box component="div" className='get-started-with-us'>Get started with us</Box>
            <Box component="div" className="get-started-desc">We would love to hear from you! Contact us and share any<br /> feedback or questions you may have.</Box>
            <Box component="div" className="get-started-desc1">We are a nonprofit fighting poverty, disease, and inequity around the world with your
              awareness</Box>
            {/*<Button component="button" className="button-edit" onClick={handleOpen}>Get In Touch</Button>*/}
            <Button component="button" className="button-edit" onClick={eyecracyCenterClick}>Eyecracy Center</Button>
          </Grid>
        </Grid>
      </Box>
      <GetInTouchModal open={open} handleClose={handleClose} />
    </>
  )
}

export default Getintouch


export const GetInTouchModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [yourMsg, setYourMsg] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [buffering, setBuffering] = useState(false);


  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (errors[name] !== undefined) {
      setErrors((prevState) => ({ ...prevState, [name]: undefined }));
    }

    if (name === 'firstName') {
      setFirstName(value);
    } else if (name === 'lastName') {
      setLastName(value);
    } else if (name === 'yourMsg') {
      setYourMsg(value);
    } else if (name === 'email') {
      setEmail(value);
    }
  };
  const handleSubmit = (event) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    event.preventDefault();

    let validationErrors = {};
    if (!firstName) {
      validationErrors.firstName = 'First name is required';
    }
    if (!lastName) {
      validationErrors.lastName = 'Last name is required';
    }
    if (!yourMsg) {
      validationErrors.yourMsg = 'Your message is required';
    }
    if (!email.match(emailRegex)) {
      validationErrors.email = 'Please enter a valid email address';
    } else {
      sendMail();
    }
    setErrors(validationErrors);

    // If no errors, submit the form and display a confirmation message
    if (Object.keys(validationErrors).length === 0) {
      setBuffering(true);
      setTimeout(() => {
        console.log(`Submitting form with First Name: ${firstName}, Last Name: ${lastName}, Your Message: ${yourMsg}, Email: ${email}`);
        setConfirmationOpen(true);
        setFirstName('');
        setLastName('');
        setYourMsg('');
        setEmail('');
        setBuffering(false);
        setTimeout(() => {
          setConfirmationOpen(false);
        }, 3000);
      }, 2000);
    }
  };

  const sendMail = async () => {
    try {
      const form_data = new URLSearchParams();

      form_data.append('html', `<div><div> Hello <strong>${firstName + " " + lastName}</strong> ,</div> <div> ${yourMsg}  </div> </div>`);
      form_data.append('Subject', 'Contact From EyeCracy');
      form_data.append('From', 'media@eyecracy.com')
      form_data.append('To', email)


      const url = "https://slnewecommerce.cloud.enliv.com/Test/testAvdhesh/mail";

      const req = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          "accept": "*/*",
          "accept-language": "en-US,en;q=0.6",
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "x-requested-with": "XMLHttpRequest"
        },
        body: form_data
      });
      const res = await req.json();

      const status = res["@attributes"].status;

      status && alert('Email sent successfully');

    }
    catch (err) {
      alert('Failed to send Email')
    }
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='form_box'>
          <div className={`${classes.root} `}>
            <Box component={'div'} className={classes.headerContainer}>
              <Box component={'div'} >
                <h1 className={classes.heading}>Get in touch with us</h1>
              </Box>
              <Box component={'div'} className="exit" style={{ height: '30px' }}>
                <Box className="ellipse-2" component="div">
                  <Box class="icon-2"></Box>
                </Box>
                <Box class="icon-exit" onClick={handleClose}>
                  &times;
                </Box>
              </Box>
            </Box>

            <form className={classes.form} onSubmit={handleSubmit} style={{ textAlign: "center" }}>
              <Box>
                <Grid container>
                  <Grid item md={12}>
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="First Name"
                      name="firstName"
                      type="text"
                      value={firstName}
                      onChange={handleInputChange}
                      error={errors.firstName !== undefined}
                      helperText={errors.firstName}
                      InputProps={{
                        style: { background: '#f7f7f8', height: '34px', fontSize: '12px', borderRadius: '10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Last Name"
                      name="lastName"
                      type="text"
                      value={lastName}
                      onChange={handleInputChange}
                      error={errors.lastName !== undefined}
                      helperText={errors.lastName}
                      InputProps={{
                        style: { background: '#f7f7f8', height: '34px', fontSize: '12px', borderRadius: '10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={handleInputChange}
                      error={errors.email !== undefined}
                      helperText={errors.email}
                      InputProps={{
                        style: { background: '#f7f7f8', height: '34px', fontSize: '12px', borderRadius: '10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Textarea
                      className={`${classes.textField} input-box`}
                      placeholder="Your Message"
                      name="yourMsg"
                      type="text"
                      style={{
                        background: "rgb(247, 247, 248)",
                        height: "34px",
                        fontSize: "12px",
                        borderDadius: "10px",
                        border: "none",
                        minHeight: "120px",
                        outline: "0"
                      }}
                      value={yourMsg}
                      onChange={handleInputChange}
                      error={errors.yourMsg !== undefined}
                      helperText={errors.yourMsg}
                      InputProps={{
                        style: { background: '#f7f7f8', height: '34px', fontSize: '12px', borderRadius: '10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Grid item md={12} sm={12}>
                <Box>
                  <Box style={{ textAlign: 'center' }}>
                    <Box component={'div'}>
                      {confirmationOpen && <p autoHideDuration={100}>Thank you for showing your interest</p>}
                    </Box>
                    <Box component={'div'}>
                      {buffering && <img src="./assets/images/Spinner_small.gif" alt="Loading" />}
                    </Box>
                  </Box>
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button className={`${classes.button} center`} variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Box>

                </Box>
              </Grid>



            </form>

          </div>
        </Box>
      </Modal>
    </>
  )
}