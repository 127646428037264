import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Button } from '@mui/material';
import './Nav.scss';
import gsap from 'gsap';
import { GeneralContext } from "../../../context/GeneralContextProvider";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Waypoint } from 'react-waypoint';
import Splitting from 'splitting';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CgInstagram } from "react-icons/cg";
import { ImLinkedin } from "react-icons/im";
import { GetInTouchModal } from '../../atoms/Getintouch';

const NewNav = () => {
    const [showNavbar, setShowNavbar] = useState(false);
    
    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };

    const Hamburger = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="24"
          viewBox="0 0 52 24"
        >
          <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
            <rect
              id="Rectangle_3"
              data-name="Rectangle 3"
              width="42"
              height="4"
              rx="2"
              transform="translate(304 47)"
              fill="#574c4c"
            />
            <rect
              id="Rectangle_5"
              data-name="Rectangle 5"
              width="42"
              height="4"
              rx="2"
              transform="translate(304 67)"
              fill="#574c4c"
            />
            <rect
              id="Rectangle_4"
              data-name="Rectangle 4"
              width="52"
              height="4"
              rx="2"
              transform="translate(294 57)"
              fill="#574c4c"
            />
          </g>
        </svg>
    );

    return (
        <>
            <Box component="div" className='newNavContainer'>
                <nav className="navbar">
                    <div className="container">
                        <div className="logo">
                            <a className="footer_main" href="/">
                                <img src="./assets/images/discoverlogo1.png" style={{ width: "70px" }} alt="Logo" />
                            </a>
                        </div>
                        <div className="menu-icon" onClick={handleShowNavbar}>
                            <Hamburger />
                        </div>
                        <div className={`nav-elements ${showNavbar && "active"}`}>
                            <ul>
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/team">Team</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/aboutUs">About Us</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/project">Projects</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/donate">Donate</NavLink>
                                </li>
                                {/* <li>  
                                    <NavLink to="/blog">Blog</NavLink>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
            </Box>
        </>
    );
};

export default NewNav;
