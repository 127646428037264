import React from 'react'
import { Grid } from '@material-ui/core'
import Box from "@material-ui/core/Box"
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Button from "@material-ui/core/Button"
import './Footer.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    TiSocialInstagram
} from "react-icons/ti";
import {
    CiLinkedin
} from "react-icons/ci";
import {
    CgInstagram
} from "react-icons/cg";
import {
ImLinkedin
} from "react-icons/im";
import PinterestIcon from '@mui/icons-material/Pinterest';


const Footer = () => {
    return (
       <Box component="div" className="footer-bg">
        <Grid container>
            <Grid item md={12} lg={12} xs={12} sm={12} className="" style={{paddingRight: "5px"}}>
                <Box className="footer-image" component="div">
                    <img src="./assets/images/discoverlogo1.png"/>
                </Box>
                <Box component="div" className='all-rights-reserved'> © 2024 Eyecracy. All rights reserved.</Box>
                <Box component="div">
                    <Grid container className="icons-padding">
                        {/* <Button className='footer-button footer-button-hover'><img className="f-edit"src="./assets/images/footer.png"/></Button>
                        <Button className='footer-button footer-button-hover'><img className="f-edit" src="./assets/images/footer.png"/></Button>
                        <Button className='footer-button footer-button-hover'><img className="f-edit" src="./assets/images/footer.png"/></Button>
                        <Button className='footer-button footer-button-hover'><img className="f-edit" src="./assets/images/footer.png"/></Button> */}
                         <Button className="facebook">
                             <a href="https://www.instagram.com/eyecracy/" target="_blank"><CgInstagram  className="f-color"/></a>
                         </Button>
                         <Button className="facebook">
                            <a href="http://linkedin.com/company/eyecracy/" target="_blank"><ImLinkedin className="f-color"/></a>
                         </Button>
                    </Grid>
                </Box>                  
            </Grid>
            {/*<Grid item md={3} lg={3} xs={12}  sm={12}>
                <Box component="div" className='download-edit'>Download</Box>
                <Box component="span" className='blog-patners'> Blog<br/>Partners<br/>Careers</Box>
            </Grid>
            <Grid item md={3} lg={3} xs={12}  sm={12}>
                <Box component="div" className="help">Help?</Box>
                <Box component="span" className='faq'>FAQ<br/>Privacy<br/>Term & conditions</Box>
            </Grid>
            <Grid item md={3} lg={3} xs={12}  sm={12}>
                <Box component="div" className="contact">
                    <a href="mailto:media@eyecracy.org" style={{color:'white'}} className="contacthover">contact</a>
                </Box>
                <Box component="span" className="support">Support</Box>
            </Grid>
             <Box component={'div'}>
                <img src="./assets/images/Footer_BG.png"/>
            </Box> */}
        </Grid>

            

       </Box>
    )
}

export default Footer