import React from 'react';
import { useEffect, useRef  } from 'react';
import { Grid } from '@material-ui/core'
import { NavLink } from 'react-router-dom';
import { Button } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box"
import NewFooter from '../../atoms/newFooter';
import './about.scss';
import Navbar from '../../atoms/navbar';
import gsap from 'gsap';
import { GeneralContext } from "../../../context/GeneralContextProvider";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Waypoint } from 'react-waypoint';
import Splitting from 'splitting'; 
import Slider from "react-slick";
import Tab from '@material-ui/core/Tab';
import { TabContext } from '@mui/lab';
import { TabList } from '@mui/lab';
import { TabPanel } from '@mui/lab';
import {
    CgInstagram
} from "react-icons/cg";
import {
ImLinkedin
} from "react-icons/im";
import { GetInTouchModal } from '../../atoms/Getintouch';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'700px',
    height: '350px',
    outline:'0',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:'20px',
    p: 4,
    '@media (max-width: 600px)': {
      top:'50%',
      width: '90%',
      maxWidth: '400px',
      height:'670px',
      padding:'10px',
    },
  };
  
  const useStyles = makeStyles((theme) => ({
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      // [theme.breakpoints.down('sm')]: {
      //   // Media query for small screens
      //   flexDirection: 'column',
      // },
    },
    heading: {
      fontSize: '22px',
      paddingLeft: '10px',
      '@media (max-width: 600px)': {
        paddingLeft: '50px',
      },
    },
    
    
  
    root: {
      position: 'relative',
    },
    mobileview: {
      textAlign:'center',
      '@media (max-width: 600px)': {
        textAlign:'center',
      },
    },
    form: {
      //display: 'flex',
      //flexDirection: 'column',
      //alignItems: 'center',
    },
    textField: {
      margin: theme.spacing(1),
      width: '300px',
      '@media (max-width: 600px)': {
        width: '250px',
        margin:'5px',
      },
    },
    notchedOutline: {
      border: 'none',
      borderRadius:'5px',
    },
    button: {
      margin: theme.spacing(2),
      textAlign:'center',
      background:'#4e78f4',
    },
    errorHelperText: {
      fontSize: '11px',
      paddingLeft: '10px',
    },
  }));


const AboutPage = () => {

    const classes = useStyles();
  const [ngoName, setNGOName] = useState('');
  const [location, setLocation] = useState('');
  const [logo, setLogo] = useState('');
  const [ein, setEIN] = useState('');
  const [representativeName, setRepresentativeName] = useState('');
  const [representativeSurname, setRepresentativeSurname] = useState('');
  const [representativeEmail, setRepresentativeEmail] = useState('');
  const [representativeRole, setRepresentativeRole] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [buffering, setBuffering] = useState(false);
  const [open, setOpen] = React.useState(false);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (errors[name] !== undefined) {
      setErrors((prevState) => ({ ...prevState, [name]: undefined }));
    }
    
    if (name === 'ngoName') {
      setNGOName(value);
    } else if (name === 'location') {
      setLocation(value);
    } else if (name === 'representativeName') {
      setRepresentativeName(value);
    } else if (name === 'representativeRole') {
      setRepresentativeRole(value);
    } 
  };
  const handleSubmit = (event) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    event.preventDefault();

    let validationErrors = {};
    if (!ngoName) {
      validationErrors.ngoName = 'NGO Name is required';
    }
    if (!location) {
      validationErrors.location = 'Location is required';
    }
    if (!representativeName) {
      validationErrors.representativeName = 'Representative Name is required';
    }
    if (!representativeRole) {
      validationErrors.representativeRole = 'Your message is required';
    }
    setErrors(validationErrors);

    // If no errors, submit the form and display a confirmation message
    if (Object.keys(validationErrors).length === 0) {
       setBuffering(true);
      setTimeout(() => {
        console.log(`Submitting form with NGO Name: ${ngoName}, Location: ${location}, EIN: ${ein}, Representative Name: ${representativeName}, Representative Surname: ${representativeSurname}, Representative Email: ${representativeEmail}, Representative Role: ${representativeRole}, Email: ${email}`);
        setConfirmationOpen(true);
        setNGOName('');
        setLocation('');
        setLogo('');
        setEIN('');
        setRepresentativeName('');
        setRepresentativeSurname('');
        setRepresentativeEmail('');
        setRepresentativeRole('');
        setEmail('');
        setBuffering(false);
        setTimeout(() => {
          setConfirmationOpen(false);
        }, 3000);
      }, 2000); 
    



    }
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

    useEffect(() => {
        AOS.init({
          easing: 'ease-out-back',
          duration: 2000,
        });

        AOS.refresh();
      }, []);


    return (
        <>
        
        <Box component="div">
            <Box component="div" class="ourTeam-Banner">
                <Box component="div" className='ourTeam_title-Box'>
                    <Box component="div" className="ourTeam_title">About Us</Box>
                    <Box component="div" className="ourTeam_breadCrumb">
                        Home / <Box component="span" className='highlightedBreadCrumb'>About Us</Box>
                    </Box>
                </Box>
            </Box>

            <Box component="div" className='nonGov_container'>
                <Grid container style={{alignItems: "center"}}>
                    <Grid item xs={12} sm={7} style={{paddingRight: "25px",}} >
                        <Box component="div" className='v1-titleBox'>
                            <Box component="div" className='dashed'></Box>
                            <Box component="div" className='v1-sectionTitle'>
                                what we do
                            </Box>
                        </Box>
                        <Box component="div" className='v1-titleDesc'>
                            We are not only <br></br>an NGO
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Box component="div" className="aboutImg-container">
                            <img src="./assets/images/about_BG.jpg"></img>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box component="div" className='vision-container'>
                <Box component="div" className='visionTitle'>
                    Our Vision
                </Box>
                <Box component="div" className='visionDesc'>
                    Eyecracy operates on a global scale, extending its reach to 
                    communities worldwide. With a mission to promote health equity and 
                    disaster preparedness across borders, we work to deliver impactful 
                    initiatives, consultations, and training programs to diverse populations 
                    around the globe. From rural villages to urban centers, Eyecracy is 
                    dedicated to making a positive difference.
                </Box>
            </Box>

            <Box component="div" className='features_container'>
                <Box component="div" className='visionTitle'>
                    Why Eyecracy & Key Features
                </Box>
                <Grid container>
                    <Grid item xs={12} sm={4} style={{padding: "0 10px"}} data-aos="flip-left" data-aos-delay="200">
                        <Box component="div" className='feature-Box'>
                            <Grid container style={{ alignItems: "center" }}>
                                <Grid item xs={12} sm={2}>
                                    <Box component="div" className='featureIcon'>
                                        <img src="./assets/images/dribbble_large.png"></img>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureTitle'> Collaborative Ecosystem:</Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureDesc'>Join a thriving ecosystem where NGOs, experts, 
                                        and change-makers converge to share insights, resources, and strategies for collective impact.
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} style={{padding: "0 10px"}} data-aos="flip-left" data-aos-delay="200">
                        <Box component="div" className='feature-Box'>
                            <Grid container style={{ alignItems: "center" }}>
                                <Grid item xs={12} sm={2}>
                                    <Box component="div" className='featureIcon'>
                                        <img src="./assets/images/dribbble_large.png"></img>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureTitle'> Expert Consultations:</Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureDesc'>
                                    Access expert consultations from leaders in global health and disaster management, 
                                    providing tailored solutions and strategic guidance.
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} style={{padding: "0 10px"}} data-aos="flip-left" data-aos-delay="200">
                        <Box component="div" className='feature-Box'>
                            <Grid container style={{ alignItems: "center" }}>
                                <Grid item xs={12} sm={2}>
                                    <Box component="div" className='featureIcon'>
                                        <img src="./assets/images/dribbble_large.png"></img>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureTitle'>Global Network:</Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureDesc'>Eyecracy proudly collaborates with renowned institutions, 
                                    including our recent affiliation with Global Health Leaders Foundation, 
                                    to bring global insights and expertise to your doorstep.
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} style={{padding: "0 10px"}} data-aos="flip-left" data-aos-delay="200">
                        <Box component="div" className='feature-Box'>
                            <Grid container style={{ alignItems: "center" }}>
                                <Grid item xs={12} sm={2}>
                                    <Box component="div" className='featureIcon'>
                                        <img src="./assets/images/dribbble_large.png"></img>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureTitle'> Innovation:</Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureDesc'>
                                    We embrace innovation as the driving force behind transformative change. 
                                    Eyecracy is at the forefront of pioneering solutions that challenge the status quo.
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} style={{padding: "0 10px"}} data-aos="flip-left" data-aos-delay="200">
                        <Box component="div" className='feature-Box'>
                            <Grid container style={{ alignItems: "center" }}>
                                <Grid item xs={12} sm={2}>
                                    <Box component="div" className='featureIcon'>
                                        <img src="./assets/images/dribbble_large.png"></img>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureTitle'>Community Impact:</Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureDesc'>
                                    Our collaborative model ensures that every effort made within the Eyecracy community resonates 
                                    beyond individual actions, 
                                    creating a lasting impact on global health and sustainable development.
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} style={{padding: "0 10px"}} data-aos="flip-left" data-aos-delay="200">
                        <Box component="div" className='feature-Box'>
                            <Grid container style={{ alignItems: "center" }}>
                                <Grid item xs={12} sm={2}>
                                    <Box component="div" className='featureIcon'>
                                        <img src="./assets/images/dribbble_large.png"></img>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureTitle'> Visionary Leadership:</Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box component="div" className='featureDesc'>
                                    Eyecracy is led by a team of visionaries and experts dedicated to creating 
                                    a world where health is not just a goal but a shared commitment.
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box component="div" className="joinUS-container" >
                <img src="./assets/images/image_9.jpg"></img>
                <Box component="div" className='joinUs-textBox' data-aos="zoom-in">
                    <Box component="div" className='joinUsBannerTitle'>
                        Join Us
                    </Box>
                    <Box component="div" className='joinUsBannerDesc'>
                        Embark on a journey towards a healthier tomorrow by becoming a part 
                        of the Eyecracy community. Whether you seek expert consultations, 
                        wish to contribute to transformative projects, or aspire to amplify your 
                        NGO's impact.
                    </Box>
                    <Box component="div" className='joinUsBannerBtn'>
                        <Button className="button-base" onClick={handleOpen} >Connect</Button>
                    </Box>
                </Box>
            </Box>

            <NewFooter />
        </Box>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={`${classes.root} `}>
            <Box component={'div'} className={classes.headerContainer}>
              <Box component={'div'} >
                <h1 className={classes.heading}>Join Us</h1>
              </Box>
              <Box component={'div'} className="exit" style={{ height: '30px' }}>
                <Box className="ellipse-2" component="div">
                  <Box class="icon-2"></Box>
                </Box>
                <Box class="icon-exit" onClick={handleClose}>
                  &times;
                </Box>
              </Box>
            </Box>
            
            <form className={classes.form} onSubmit={handleSubmit}>
                <Box>
                  <Box component={'div'} className='ngo-details'>NGO Details:</Box>
                  <Grid container>
                    <Grid item md={6} className={classes.mobileview}>
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="NGO Name"
                      name="ngoName"
                      type="text"
                      value={ngoName}
                      onChange={handleInputChange}
                      error={errors.ngoName !== undefined}
                      helperText={errors.ngoName}
                      InputProps={{
                        style: { background: '#f7f7f8', height: '34px', fontSize: '12px',borderRadius:'10px'},
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />

                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Location"
                      name="location"
                      type="text"
                      value={location}
                      onChange={handleInputChange}
                      error={errors.location !== undefined}
                      helperText={errors.location}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    </Grid>
                    <Grid item md={6} className={classes.mobileview}>
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Representative Name"
                      name="representativeName"
                      type="text"
                      value={representativeName}
                      onChange={handleInputChange}
                      error={errors.representativeName !== undefined}
                      helperText={errors.representativeName}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    <TextField
                      className={`${classes.textField} input-box`}
                      placeholder="Your Message"
                      name="representativeRole"
                      type="text"
                      value={representativeRole}
                      onChange={handleInputChange}
                      error={errors.representativeRole !== undefined}
                      helperText={errors.representativeRole}
                      InputProps={{
                        style: { background: '#f7f7f8',height:'34px',fontSize:'12px',borderRadius:'10px' },
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      FormHelperTextProps={{
                        style: { margin: '0' },
                        classes: {
                          root: `${classes.errorHelperText}`,
                        },
                      }}
                    />
                    </Grid>
                  </Grid>
                </Box>
                <Grid item md={12} sm={12}>
                  <Box>
                  <Box style={{textAlign:'center'}}>
                    <Box component={'div'}>
                  {confirmationOpen && <p autoHideDuration={100}>Thank you for showing your interest</p>}
                  </Box>
                  <Box component={'div'}>
                  {buffering && <img src="./assets/images/Spinner_small.gif" alt="Loading" />}
                  </Box>
                  </Box>
                  <Box style={{display:'flex',justifyContent:'center', marginTop: "40px"}}>
                    <Button className={`${classes.button} center`} variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Box>
                  
                  </Box>
                  </Grid>
                   

            
            </form>
            
          </div>
        </Box>
      </Modal>
        </>
    )
}

export default AboutPage;