import React from "react";
import { Grid } from '@material-ui/core'
import { Switch } from "@mui/material";
import { Route } from "react-router";
import Donationdash from "../../pages/NGO_donation";
import Myproject from "../../pages/NGO_myproject";
import Schedule from "../../pages/NGO_schedule";
import Button from '@mui/material/Button';
import Box from "@material-ui/core/Box"
import './navbar.scss';
import { useState } from "react";
import App from "../../../App";
import HomePage from "../../pages/home";
import Sidebar from "../../Sidebar";
import { GeneralContext } from "../../../context/GeneralContextProvider";
import $ from "jquery";


const donateClick = () =>{
  if((window.location.host).indexOf("localhost")>-1) {
      window.location.href = "/donate";
  } else {
      window.location.href = "/donate";
  }
}

const Navbar = () => {

  const { showSideBar, setShowSideBar } = React.useContext(GeneralContext)
  const [isOpen, setIsOpen] = useState(GeneralContext)
//    const [visible, setVisible] = useState(false);
//    const [show, setShow] = useState(false);
    
//    const removeElement = () => {
//      setVisible((prev) => !prev);
//      setShow(true)
//  }
  const removeElement = () =>{
    setIsOpen(true)
    if(showSideBar){
      setShowSideBar(false);
    }else{
    setShowSideBar(true);
    try{
    $(".sidebar").css("display", "block !important")
    }catch(e){
    }
    }
    console.log( "testing ----", isOpen , showSideBar)
  }

  const homeClick = () =>{
    var currentUrl = window.location.pathname;
    if (currentUrl == "/" || currentUrl == "/eyecracy") {
    
    } else if((window.location.host).indexOf("localhost")>-1)  {
      window.location.href = "/";
    } else {
        window.location.href = "/eyecracy";
      }
    }
 
    return (
      <>
     <Box component="div" className="nav-width" >
      
    <nav>
         <Grid item md={4} lg={4} xs={4}>
                <Box className="BurgerMenu-container"  
                 onClick={removeElement}
                >
                  <Box component="div" className="ham_hover">
                        <Box className="burger"></Box>
                        <Box className="burger"></Box>
                        <Box className="burger"></Box> 
                  </Box>
                </Box>
          </Grid>
          <Grid item md={4} lg={4} xs={4}>
            <Box component="div" className="image-place">
              <img className="eyecracy" onClick={homeClick} src="./assets/images/discoverlogo1.png" style={{height: "80px", width: "85px", objectFit: "contain",}}/>
              </Box>
          </Grid>
          <Grid item md={4} lg={4} xs={4}>
              <Box component="div" className="donate-btn-place">
                  <Button className="button-base" onClick={donateClick}>Donate</Button>
              </Box>
                      
          </Grid>
        </nav>

      </Box>
     {/* {
    
    visible&&( 
    <>
     <Sidebar>
      <Switch>
        <Route path="/events" >
            <Schedule />
        </Route>
        <Route path="/Dashboard">
          <Donationdash />
        </Route> 
        <Route path="/myprojects">
          <Myproject />
        </Route>
        <Route path="/campaign" >
            <Campaign />
        </Route>
        <Route path="/settings">
            <Settings />
        </Route>
          <Route path="/" >
            <HomePage />
        </Route> 
         
      </Switch>
    </Sidebar>  */}
         </>
      //  ) }
      
      
    
      
      
  


    ) 
}

export default Navbar;