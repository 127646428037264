import React from 'react';
import { useEffect, useRef  } from 'react';
import { Grid } from '@material-ui/core'
import { NavLink } from 'react-router-dom';
import { Button } from '@mui/material';
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box"
import './footer.scss';
import gsap from 'gsap';
import { GeneralContext } from "../../../context/GeneralContextProvider";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Waypoint } from 'react-waypoint';
import Splitting from 'splitting'; 
import Slider from "react-slick";
import Tab from '@material-ui/core/Tab';
import { TabContext } from '@mui/lab';
import { TabList } from '@mui/lab';
import { TabPanel } from '@mui/lab';

const NewFooter = () => {
    return (
        <Box component="div" className='footer_section'>
            <Grid container>
                <Grid item xs={12} sm={2}>
                    <Box component="div" className="logo">
                    <a class="footer_main" href="/"><img src="./assets/images/eyecracy_logo_WBG.png" style={{ width: "90px", position: "relative", top: "-10px"}}></img></a>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Box component="div" className="footer_main"><a class="footer_main" href="/">Home</a></Box>
                    <Box component="div" className="footer_subLink"><a class="footer_subLink" href="/aboutUs">About Us</a></Box>
                    <Box component="div" className="footer_subLink"><a class="footer_subLink" href="/team">Team</a></Box>
                    {/*<Box component="div" className="footer_subLink">What we do</Box>
                    <Box component="div" className="footer_subLink">Connect</Box>*/}
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Box component="div" className="footer_main">More</Box>
                    <Box component="div" className="footer_subLink"><a class="footer_subLink" href="/project">Projects</a></Box>
                    <Box component="div" className="footer_subLink"><a class="footer_subLink" href="/donate">Donate</a></Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Box component="div" className="footer_main">Connect</Box>
                    <Box component="div" className="footer_subLink">
                        <a className="footer_subLink" href="https://www.instagram.com/eyecracy?igsh=MWZxMW1wb2t4ODN1dA==" target="_blank">Instagram</a>
                    </Box>
                    <Box component="div" className="footer_subLink">
                        <a className="footer_subLink" href="https://www.linkedin.com/company/eyecracy/" target="_blank">LinkedIn</a>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box component="div" className='subscribe_text'>
                        Subscribe to get latest updates
                    </Box>
                    <Grid container>
                        <Grid item xs={12} sm={8}>
                            <input style={{width:'100%' , padding:'7px'}}
                                className="email" 
                                placeholder='Your email'
                                type="email"
                                required="true"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} style={{padding: "0"}}>
                            <Box component="div" className='weDoBtn-container'>
                                <Button className="button-base">Subscribe</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default NewFooter;