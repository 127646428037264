import React from 'react'
// import { Grid } from '@material-ui/core'
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import './banner.scss'
// import { AudioCard, VideoCard } from 'material-ui-player'
// import {Grid} from '@mui/material/Grid';
// import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import Box from '@mui/material/Box';



const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  }));
  const donateClick = () =>{
    if((window.location.host).indexOf("localhost")>-1) {
        window.location.href = "/donate";
    } else {
        window.location.href = "/donate";
    }
  }

  const discoverClick = () =>{
    if((window.location.host).indexOf("localhost")>-1) {
        window.location.href = "/discover";
    } else {
        window.location.href = "/discover";
    }
  }
  
 
const Banner = () => {
    return (



       <Box component="div" className="banner-section-background-container">
            <Grid container className='card-edit'>
                <Grid item sm={12} md={6} lg={6} xs={12} className="banner-section-edit">
                    <Box className="section-title" component="div">Use your time spent online to raise funds for your cause</Box>
                    <Box className="section-desc" component="div">Being online as you have never been.</Box>
                    <Button className="button-base" onClick={discoverClick}>Discover Us</Button>
                </Grid>
                <Grid item sm={12} md={6} lg={6} xs={12}>
                    <Box component="div">
                         <Grid container>
                            <video className='vedio-edit' controls style={{boxhadow: "none"}}>
                                <source src={'./assets/vedio/file_example_MP4_1280_10MG.mp4'} type = "video/mp4"/>
                                    This browser doesn't support video tag.
                            </video>
                         </Grid>
                    </Box>    
                </Grid>
            </Grid>
       </Box>
    )
}

export default Banner
